import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
    OnInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    NgControl,
    Validators,
} from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipSettings, ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';

@UntilDestroy()
@Component({
    selector: 'bazis-input-symbol',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputSymbolComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() symbolAmount: number = 1;

    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // заголовок поля
    @Input() title: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для placeholderKey
    @Input() placeholderKey: string = '';

    // параметры для placeholder
    @Input() placeholder: string = '';

    // параметры для title
    @Input() titleParams: any = null;

    // параметры для note
    @Input() noteParams: any = null;

    // отображение метки поля выделенным стилем
    @Input() hasTitleMajor: boolean = false;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() required$: Observable<boolean> = null;

    // для всплытия состояния
    @Output() touched = new EventEmitter();

    // для всплытия состояния
    @Output() allSymbolsEntered = new EventEmitter();

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    @Input() type = 'text';

    @ViewChild('group') group: ElementRef;

    fields = new FormArray([]);

    onChange = (_: any) => {};

    onTouched = () => {};

    isTouched = false;

    isFocused = false;

    canEraserShow = false;

    valueChanges$ = this.fields.valueChanges.pipe(
        tap((value) => {
            const str = value.join('');
            this.onChange(str);
            if (str.length === this.symbolAmount) {
                this.allSymbolsEntered.emit();
            }
        }),
    );

    constructor(@Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
        ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.cdr.markForCheck();
        }
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }
        for (let i = 0; i < this.symbolAmount; i++) {
            (this.fields as FormArray).push(new FormControl('', [Validators.required]));
        }
        setTimeout(() => {
            this.setFocusInFieldByIndex(0);
        });
        if (this.ngControl.control.value) {
            this.writeValue(this.ngControl.control.value);
        }
    }

    onInput(index) {
        if (`${this.fields.value[index]}`.length > 1) {
            this.fields.controls[index].setValue(this.fields.value[index][0]);
        }
        if (index < this.symbolAmount - 1 && this.fields.value[index]) {
            this.setFocusInFieldByIndex(index + 1);
        }
    }

    onBackspace(index) {
        if (index > 0) {
            this.setFocusInFieldByIndex(index - 1);
        }
    }

    getElementByIndex(index = 0) {
        return this.group.nativeElement.children[index].children[0];
    }

    setFocusInFieldByIndex(index = 0) {
        this.getElementByIndex(index).focus();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.fields.disable();
        } else {
            this.fields.enable();
        }
    }

    public writeValue(value: any): void {
        this.fields.controls.forEach((control) => {
            control.setValue('', { emitEvent: false });
        });

        value.split('').forEach((symbol, i) => {
            if (i < this.symbolAmount && this.fields.controls.length > i) {
                this.fields.controls[i].setValue(symbol, { emitEvent: false });
            }
        });
        this.fields.updateValueAndValidity();
    }

    public onFocus(index = 0) {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
        this.isFocused = true;
        const length = this.fields.controls[index].value.length;
        if (length > 0) {
            this.getElementByIndex(index).select(0, length);
        }
    }
}
