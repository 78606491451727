import { MapLayerSettings } from '@bazis/map/models/map.models';
import * as L from 'leaflet';

export abstract class RouteLineClass {
    static processLayerSettings(layer: MapLayerSettings) {
        ['default', 'selected'].forEach((layerState) => {
            if (layerState !== 'default' && !layer.line[layerState]) return;

            layer.line[layerState] = {
                ...layer.line.default,
                ...layer.line[layerState],
            };

            layer[`${layerState}Options`] = layer.line[layerState];
        });
        return layer;
    }

    static drawRouteLine(
        layer: MapLayerSettings,
        featureGroup: L.FeatureGroup,
        renderer,
        zoom: number,
    ) {
        const objects =
            zoom !== null && layer.zoomObjects ? layer.zoomObjects[zoom] : layer.objects;
        objects.forEach((object) => {
            const line = L.polyline(
                object.coordinates as L.LatLngExpression[],
                {
                    ...layer.line.default,
                    ...object?.line?.default,
                    bubblingMouseEvents: false,
                    interactive: layer.isInteractive === undefined ? true : layer.isInteractive,
                    renderer,
                    properties: {
                        id: object.id,
                        popupParams: {
                            id: object.id,
                            layerId: layer.id,
                            ...object.params,
                        },
                    },
                } as L.PolylineOptions,
            );
            line.addTo(featureGroup);
        });
    }
}
