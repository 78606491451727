import { Injectable } from '@angular/core';
import { flipObject } from '@bazis/utils';
import { IsActiveMatchOptions } from '@angular/router';
import { MaskSettings } from '@bazis/form/models/form-element.types';
import { BazisConfigurationService } from '@bazis/configuration.service';
import * as bazisConfig from '@bazis/configuration.service';

// default api prefix
export const API_URL = '/api/web/v1';

// language settings
export const AVAILABLE_LANGUAGES = ['ru', 'en'];
export const SELECTED_LANGUAGE =
    AVAILABLE_LANGUAGES.indexOf(localStorage.getItem('lang')) > -1
        ? localStorage.getItem('lang')
        : AVAILABLE_LANGUAGES[0];

// settings {[fron-lang-id]: back-lang-id } (for faq and legal docs)
export const API_DOC_LANGS_MAP = {
    ru: 'ru',
};
export const VAT = 20;

// datetime formats which are typical for API
export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss+00:00';

// links for documents
export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
    agencyContract: '/legal-documents/agency_contract',
    partnerContract: '/legal-documents/partner_contract',
};

// ROLES settings
export const ROLE = {
    clientOrg: 'WW_LK_OO_UL',
    client: 'WW_LK_OO_FL',
    transporter: 'WW_LK_OT',
    waterUtility: 'WW_LK_OI',
    systemOperator: 'WW_LK_ADMIN',
    anonymous: 'WW_LK_PUBLIC_USER',
    base: 'WW_LK_BASE_USER',
    director: 'WW_LK_SUPERVISOR',
    callOperator: 'WW_LK_CALLOPERATOR',
    driver: 'WW_LK_DRIVER',
    empty: null,
};
export const ROLE_REVERT = flipObject(ROLE);

export const ROLE_ACCOUNT_TYPE = {
    [ROLE.clientOrg]: 'customer',
    [ROLE.transporter]: 'executor',
    [ROLE.systemOperator]: 'executor',
};

export const MOBILE_ROLES = [ROLE.driver];
export const DISABLE_NOTIFICATIONS_ROLES = [ROLE.anonymous, ROLE.base, ROLE.client];

// Auth settings
export const AUTH_PAGE_URL = '/login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

// tech settings
export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const PHONE_PATTERN = bazisConfig.PHONE_PATTERN;

export const VEHICLE_MASK_SETTINGS: Partial<MaskSettings> = bazisConfig.VEHICLE_MASK_SETTINGS;
export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = bazisConfig.PHONE_MASK_SETTINGS;

export const PHONE_INPUT_TRANSFORM_FN = bazisConfig.PHONE_INPUT_TRANSFORM_FN;

export const OKTMO_PATTERN = /^([0-9]{11})$/;

export const BIC_PATTERN = bazisConfig.BIC_PATTERN;
export const RRC_PATTERN = bazisConfig.RRC_PATTERN;
export const INN_PATTERN = bazisConfig.INN_PATTERN;
export const BANK_ACCOUNT_PATTERN = bazisConfig.BANK_ACCOUNT_PATTERN;
export const CORRESPONDENT_ACCOUNT_PATTERN = bazisConfig.CORRESPONDENT_ACCOUNT_PATTERN;
export const OKTMO_MASK_SETTINGS = bazisConfig.OKTMO_MASK_SETTINGS;
export const MSRN_MASK_SETTINGS = {
    mask: '0*',
    showMaskTyped: false,
    clearIfNotMatch: false,
    validation: true,
};
export const KBK_PATTERN = /^[0-9a-zа-яА-ЯA-Z]{20}$/;

export const UNDERCONSTRUCTION_TITLE = {
    section: 'title.sectionUnderconstruction', // for tabs which are underconstruction
};

// Global search settings, TODO: request them from API
export const ALL_SEARCH_MODELS = [
    {
        id: 'all',
        nameKey: 'search.all',
    },
    {
        id: 'long_term.contract',
        nameKey: 'search.contracts',
        urlStart: '/contracts/',
    },
];

export const ROLE_SEARCH_MODEL_IDS = {
    [ROLE.clientOrg]: ['all'],
    [ROLE.client]: ['all'],
    [ROLE.transporter]: ['all'],
    [ROLE.waterUtility]: ['all'],
    [ROLE.director]: [],
    [ROLE.callOperator]: ['all'],
    [ROLE.systemOperator]: ALL_SEARCH_MODELS.map((v) => v.id),
};

// Project constants
export const FAQ_TG_CHANNEL_LINK = null;
export const TG_BOT = '@GoEco_Service_bot';
export const TG_COMMAND = null;

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions =
    bazisConfig.DEFAULT_LINK_ACTIVE_MATCH_OPTIONS;

export const USER_STATUSES = {
    active: {
        color: 'success',
        nameKey: 'director.users.status.active',
    },
    waiting: {
        color: 'secondary',
        nameKey: 'director.users.status.waiting',
    },
    blocked: {
        color: 'error',
        nameKey: 'director.users.status.blocked',
    },
};

export const HEADER_MENU = {
    [ROLE.clientOrg]: [
        {
            titleKey: 'header.createOrder',
            url: '/bid/order/new',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
    ],
    [ROLE.client]: [
        {
            titleKey: 'header.createOrder',
            url: '/bid/order/new',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
    ],
    [ROLE.callOperator]: [
        {
            titleKey: 'header.createOrder',
            url: '/bid/order/new',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.organizations',
            url: '/organizations',
        },
    ],
    [ROLE.transporter]: [
        {
            titleKey: 'header.myVehicles',
            url: '/vehicles',
        },
        {
            titleKey: 'header.offers',
            url: '/offers',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/tasks',
        },
    ],
    [ROLE.waterUtility]: [
        {
            titleKey: 'header.waterUtilities',
            url: '/water-utilities',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/tasks',
        },
    ],
    [ROLE.systemOperator]: [
        {
            titleKey: 'header.organizations',
            url: '/organizations',
        },
        {
            titleKey: 'header.waterUtilities',
            url: '/water-utilities',
        },
        {
            titleKey: 'header.vehicles',
            url: '/vehicles',
        },
        {
            titleKey: 'header.offers',
            url: '/offers',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/tasks',
        },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.roleRequests',
                    url: '/director/requests',
                },
                {
                    titleKey: 'header.operatorReports',
                    url: '/operator-reports',
                    queryParams: { status: 'draft' },
                },
            ],
        },
    ],
    [ROLE.anonymous]: [
        {
            titleKey: 'header.about',
            url: '/about',
        },
        {
            titleKey: 'header.infoCenter',
            url: '/',
            children: [
                {
                    titleKey: 'header.agreement',
                    url: DOCUMENT_LINKS.userAgreement,
                },
                {
                    titleKey: 'header.faq',
                    url: '/faq',
                },
            ],
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'header.userInitial',
            url: '/user-initial',
        },
    ],
    [ROLE.director]: [
        {
            titleKey: 'header.companyUsers',
            url: '/director/users',
        },
        {
            titleKey: 'header.roleRequests',
            url: '/director/requests',
        },
    ],
    [ROLE.empty]: [],
};
export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.account',
        url: '/profile/account',
        icon: 'credit-card',
        excludeForRoles: [
            ROLE.base,
            ROLE.director,
            ROLE.client,
            ROLE.callOperator,
            ROLE.waterUtility,
            ...MOBILE_ROLES,
        ],
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
        excludeForRoles: DISABLE_NOTIFICATIONS_ROLES,
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];
const COMMON_FOOTER_MENU = [
    {
        titleKey: 'footer.personalInfo',
        url: '/profile/personal-info',
    },
    {
        titleKey: 'footer.profileSettings',
        url: '/profile/settings',
    },
];
const FOOTER_MENU_FL = [
    {
        titleKey: 'footer.personalInfo',
        url: '/profile/personal-info',
    },
];
export const FOOTER_MENU = {
    [ROLE.clientOrg]: COMMON_FOOTER_MENU,
    [ROLE.client]: FOOTER_MENU_FL,
    [ROLE.transporter]: COMMON_FOOTER_MENU,
    [ROLE.waterUtility]: COMMON_FOOTER_MENU,
    [ROLE.systemOperator]: COMMON_FOOTER_MENU,
    [ROLE.base]: COMMON_FOOTER_MENU,
    [ROLE.director]: COMMON_FOOTER_MENU,
    [ROLE.callOperator]: COMMON_FOOTER_MENU,
};

export const AVG_SPEED = 50;

export const SL_ORG_TIN = '7707459899';

export const COLORS = {
    primary: '#FFCC00',
    secondary: '#43B1F2',
    tertiary: '#817AF9',
    action: '#3356D7',
    info: '#C4C4C4',
    success: '#78C649',
    warning: '#F6BF36',
    danger: '#ED1A34',
    light: '#f4f5f8',
    medium: '#92949c',
    dark: '#111214',
};

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BazisConfigurationService {
    selectedLanguage = SELECTED_LANGUAGE;

    availableLanguages = AVAILABLE_LANGUAGES;

    protected configuration = {
        tiles: {
            default: {
                url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                size: 256,
            },
        },
        emptyInputValue: '-',
        defaultLocation: [56.116765, 47.242928],
        contacts: { email: 'info@goeco21.ru', phone: '8 (800) 301-41-61' },
        vehicleNumberMask: ['V', '000', 'VV', '000'],
    };
}
