<ng-container *transloco="let t">
    <div
        *ngIf="ngControl.control"
        class="bazis-control"
        [class.bazis-control--focused]="isFocused"
        [class.bazis-control--dirty]="ngControl.control.value"
    >
        <ng-container
            *ngIf="{
                required: required$ | async,
                valueChanges: valueChanges$ | async
            } as data"
        >
            <label
                class="bazis-control__label"
                *ngIf="titleKey || title"
            >
                {{ titleKey ? t(titleKey, titleParams) : title }}
                <span
                    color="danger"
                    *ngIf="data.required"
                >
                    *
                </span>
            </label>
            <label
                class="bazis-control__label"
                *ngIf="isEmptyLabel"
                >&nbsp;</label
            >

            <div [class.bazis-control__field-group-wrapper]="hasStepButton">
                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="field.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <div
                        *ngIf="leftIcon"
                        class="bazis-control__field-group__start"
                    >
                        <bazis-icon [icon]="leftIcon"></bazis-icon>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                        <div
                            #tooltipLeft
                            class="bazis-control__field-group__start"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                            <bazis-tooltip
                                [reference]="tooltipLeft"
                                placement="bottom-start"
                                [isStatic]="true"
                            >
                                {{ t(tooltipKey, tooltipParams) }}
                            </bazis-tooltip>
                        </div>
                    </ng-container>
                    <input
                        type="number"
                        class="bazis-control__field"
                        [class.bazis-control__field--erasable]="hasEraser"
                        [formControl]="field"
                        [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                        (focus)="onFocus()"
                        (blur)="onBlur()"
                        (keydown)="onKeyDown($event)"
                    />
                    <div
                        *ngIf="
                            unitKey ||
                            unit ||
                            rightIcon ||
                            (ngControl.control.value && canEraserShow)
                        "
                        class="bazis-control__field-group__end"
                    >
                        <bazis-button
                            *ngIf="canEraserShow"
                            [class.bh-hidden]="!ngControl.control.value"
                            fill="clear"
                            (click)="onClear()"
                            class="bazis-eraser"
                        >
                            <bazis-icon
                                size="s"
                                name="cross-small"
                            ></bazis-icon>
                        </bazis-button>
                        <span
                            *ngIf="unitKey || unit"
                            class="bazis-control__units"
                            [innerHTML]="unit || t(unitKey)"
                        ></span>
                        <ng-container *ngIf="rightIcon">
                            <bazis-icon
                                *ngIf="isRightIconClickable && !data.readonly"
                                [icon]="rightIcon"
                                (click)="onRightIconClick($event)"
                            ></bazis-icon>
                            <bazis-icon
                                *ngIf="!isRightIconClickable"
                                [icon]="rightIcon"
                            ></bazis-icon>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                        <div
                            #tooltipRight
                            class="bazis-control__field-group__end"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                            <bazis-tooltip
                                [reference]="tooltipRight"
                                placement="bottom-end"
                                [isStatic]="true"
                            >
                                {{ t(tooltipKey, tooltipParams) }}
                            </bazis-tooltip>
                        </div>
                    </ng-container>
                </div>

                <bazis-button
                    *ngIf="increment && hasStepButton && !field.disabled"
                    fill="outline"
                    color="action"
                    [disabled]="
                        minNumber !== undefined &&
                        minNumber !== null &&
                        (field.value || 0) - increment < minNumber
                    "
                    (click)="addToValue(-increment)"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="minus"
                    ></bazis-icon>
                </bazis-button>
                <bazis-button
                    *ngIf="increment && hasStepButton && !field.disabled"
                    fill="outline"
                    color="action"
                    [disabled]="
                        maxNumber !== undefined &&
                        maxNumber !== null &&
                        (field.value || 0) + increment > maxNumber
                    "
                    (click)="addToValue(+increment)"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="plus"
                    ></bazis-icon>
                </bazis-button>
            </div>

            <div class="bazis-control__error">
                <bazis-form-error
                    [formControl]="ngControl.control"
                    [validationErrorMessages]="validationErrorMessages"
                ></bazis-form-error>
                <ng-content></ng-content>
            </div>

            <div
                *ngIf="noteKey"
                class="bazis-control__note"
            >
                {{ t(noteKey, noteParams) }}
            </div>
        </ng-container>
    </div>
</ng-container>
