import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazisSelectSearchComponent } from '@bazis/form/components/select-search.component';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent extends BazisSelectSearchComponent implements AfterViewInit {
    @Input() hasChangeLink = false;

    isVisibleField = true;

    ngAfterViewInit(): void {
        if (this.hasChangeLink) {
            this.isVisibleField = this.ngControl.value ? false : true;
        }
    }

    showField(e) {
        this.isVisibleField = true;
        this.showList = true;
        this.isFocused = true;
        super.toggleList();
    }

    toggleList() {
        this.mobileToggleList();
        super.toggleList();
    }

    toggleValue(id: string = '') {
        super.toggleValue(id);
        this.mobileToggleList();
        if (this.showList) this.showList = false;
    }

    focusField() {
        this.isFocused = true;
        this.showList = true;
        this.isVisibleField = true;
    }

    mobileToggleList() {
        if (this.hasChangeLink) {
            this.isVisibleField = this.ngControl.value ? false : true;
            this.isFocused = false;
        }
    }

    hideListMobile() {
        this.showList = false;
        this.mobileToggleList();
    }
}
