import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BazisInputAddressComponent } from '@bazis/form/components/input-address.component';

@UntilDestroy()
@Component({
    selector: 'app-input-address',
    templateUrl: './input-address.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAddressComponent extends BazisInputAddressComponent implements OnInit {
    isVisibleField = false;

    showField(e) {
        this.isVisibleField = true;
        super.focusField(e);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.ngControl.value) {
            this.isVisibleField = true;
        }
    }

    // blurField(e) {
    //     this.isVisibleField = false;
    //     super.blurField(e);
    // }

    selectFromList(value) {
        this.isVisibleField = false;
        super.selectFromList(value);
    }
}
