import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { BazisBreadcrumbsService } from '@bazis/shared/services/breadcrumbs.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    // TODO: (пометка для себя)
    // Идеи из https://ionicframework.com/docs/api/breadcrumbs
    // посмотреть вариант доработки до web- компонента

    @Input() iconSeparator: string;

    @Input() doNotDisplayLength: number = 1;

    list$ = this.breadcrumbs.breadcrumbs$;

    constructor(
        public breadcrumbs: BazisBreadcrumbsService,
        public mqSrv: BazisMediaQueryService,
        private configurationService: BazisConfigurationService,
    ) {
        if (!this.iconSeparator) {
            this.iconSeparator = this.configurationService.breadcrumbsIconSeparator;
        }
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
