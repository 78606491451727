<div
    class="bazis-modal__content"
    *ngIf="{ info: mapInfo$ | async } as data"
>
    <ng-container *ngIf="data.info">
        <h4>{{ data.info.title }}</h4>
        <p *ngIf="data.info.description">{{ data.info.description }}</p>
        <ng-container *ngIf="data.info.point">
            <bazis-coordinates [point]="data.info.point"></bazis-coordinates>
        </ng-container>
    </ng-container>
    <div class="bazis-map-wrapper">
        <bazis-map
            [mapSettings$]="mapSettings$"
            [mapLayers$]="mapLayers$"
        ></bazis-map>
    </div>
</div>
