import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SwAuthService } from '@shared/services/auth.service';
import { BehaviorSubject, of, shareReplay, Subject, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BazisCryptoService } from '@bazis/signature/crypto/crypto.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';

@Component({
    selector: 'app-modal-signature',
    templateUrl: './modal-signature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSignatureComponent implements OnInit {
    save$ = new Subject();

    auth$;

    pluginError = false;

    certificate;

    certificates$ = this.cryptoService.selectCertificate().pipe(
        tap((v) => {
            this.pluginError = false;
        }),
        catchError((e) => {
            this.pluginError = true;
            return of(e);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    isProcessing$ = new BehaviorSubject(false);

    ngOnInit() {
        this.auth$ = this.save$.pipe(
            tap((v) => {
                this.isProcessing$.next(true);
            }),
            switchMap((v) =>
                this.authService.authBySignature$(this.certificate).pipe(
                    catchError((e) => {
                        this.isProcessing$.next(false);
                        return of(e);
                    }),
                ),
            ),
            tap((v) => {
                this.isProcessing$.next(false);
                this.close();
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    constructor(
        private authService: SwAuthService,
        private cryptoService: BazisCryptoService,
        private modalService: BazisModalService,
    ) {}

    close() {
        this.modalService.dismiss();
    }
}
