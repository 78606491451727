import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-button-open-entity',
    templateUrl: './button-open-entity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonOpenEntityComponent {
    @Input() href: string = '';
}
