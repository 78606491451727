<h6 *ngIf="settings.title">{{ settings.title }}</h6>
<p class="bazis-p_minor" *ngIf="settings.description">{{ settings.description }}</p>
<div *ngFor="let button of settings.buttons">
    <bazis-button class="bh-block"
        color="action"
        (click)="click(button.action, settings.id)"
    >
        <ng-container *ngIf="button.titleKey">
            {{ button.titleKey | transloco }}
        </ng-container>
        <ng-container *ngIf="button.title">{{ button.title }}</ng-container>
    </bazis-button>
</div>
