import { Observable, of, Subject } from 'rxjs';
import { EntList } from '@bazis/shared/models/srv.types';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { buildFilterStr } from '@bazis/utils';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { Injectable } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';

@Injectable()
export class SignUpValidators {
    constructor(private entityService: BazisEntityService, private srv: BazisSrvService) {}

    organizationValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (control.value.length === 0) return of(null);

            return this.entityService
                .getEntityList$('organization.organization_info', {
                    params: {
                        filter: buildFilterStr({
                            tin: control.value,
                        }),
                    },
                })
                .pipe(
                    map((r: EntList) =>
                        r.list.length > 0 && r.list[0].$snapshot.has_users_admin
                            ? { signUpOrganizationValidator: true }
                            : null,
                    ),
                    catchError((e) => of({ signUpOrganizationValidator: true })),
                );
        };
    }

    tinValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (control.value.length === 0) return of(null);

            return this.srv
                .commonGetRequest$('dadata/suggest_organization', { data: control.value })
                .pipe(
                    map((r) =>
                        r.length === 0 || r.findIndex((v) => v.data.inn === control.value) === -1
                            ? { tinOrganizationValidator: true }
                            : null,
                    ),
                    catchError((e) => of(null)),
                );
        };
    }
}
