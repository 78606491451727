import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
    public static equalValidatorFn(passwordControl, repeatPasswordControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return !passwordControl.value ||
                !repeatPasswordControl.value ||
                passwordControl.value === repeatPasswordControl.value
                ? null
                : { notEqual: true };
        };
    }

    public static noNumbersValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return !control.value || /\d/.test(control.value) ? null : { noNumbers: true };
        };
    }
    public static noUppercaseLettersValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return !control.value || /[A-Z]/.test(control.value)
                ? null
                : { noUppercaseLetters: true };
        };
    }
    public static noLowercaseLettersValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return !control.value || /[a-z]/.test(control.value)
                ? null
                : { noLowercaseLetters: true };
        };
    }
    public static noSpecialSymbolsValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return !control.value || /[@$!%*#?&.,-]/.test(control.value)
                ? null
                : { noSpecialSymbols: true };
        };
    }
    public static notValidSymbolsValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return control.value && /[^a-zA-Z0-9@$!%*#?&.,-]/.test(control.value)
                ? { notValidSymbols: true }
                : null;
        };
    }
}
