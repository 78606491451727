import * as L from 'leaflet';
import { MapGeometry } from '@bazis/map/models/map.models';
export function fromGeoJson(geometry: {
    type: string;
    coordinates: any[];
}): L.LatLngTuple | L.LatLngTuple[] | L.LatLngTuple[][] {
    if (geometry.type === 'Point') {
        return [geometry.coordinates[1], geometry.coordinates[0]];
    }
    if (geometry.type === 'LineString') {
        return geometry.coordinates.map((coordinate) => [coordinate[1], coordinate[0]]);
    }
    if (geometry.type === 'MultiLineString' || geometry.type === 'Polygon') {
        return geometry.coordinates.map((line) =>
            line.map((coordinate) => [coordinate[1], coordinate[0]]),
        );
    }
    if (geometry.type === 'MultiPolygon') {
        return geometry.coordinates.map((polygonWrapper) =>
            polygonWrapper.map((polygon) =>
                polygon.map((coordinate) => [coordinate[1], coordinate[0]]),
            ),
        );
    }
    return geometry.coordinates;
}

export const _generateGeoJSONPoints = (numPoints, boundingBox) => {
    const points = [];

    for (let i = 0; i < numPoints; i++) {
        const lat = boundingBox[1] + Math.random() * (boundingBox[3] - boundingBox[1]);
        const lng = boundingBox[0] + Math.random() * (boundingBox[2] - boundingBox[0]);
        points.push({
            type: 'Point',
            coordinates: [lng, lat],
        });
    }

    return points;
};

export const getPolygonCenter = (polygon: MapGeometry) => {
    return L.polygon(fromGeoJson(polygon) as L.LatLngTuple[])
        .getBounds()
        .getCenter();
};

export const clusterizer = (
    points: { point: any; id: string }[],
    icons: { width: number; height: number; zoomMin: number; zoomMax: number }[],
    clusterIcon: { width: number; height: number },
    zoomMax = 18,
    zoomMin = 0,
    clusterLastZoom = false,
) => {
    // const icon = {
    //     width: 20,
    //     height: 20,
    // };
    // const clusterIcon = {
    //     width: 50,
    //     height: 50,
    // };

    // const zoomMax = 18;
    // const zoomMin = 0;

    // const points = _generateGeoJSONPoints(100, [-180, -90, 180, 90]).map((point, i) => {
    //     return {
    //         point,
    //         id: i + 1 + '',
    //     };
    // });
    const latlngPoints = points.map((v) => {
        return {
            ...v,
            latLng: L.latLng(v.point.coordinates[1], v.point.coordinates[0]),
        };
    });
    const clusters = {};
    const crs = L.CRS.EPSG4326;
    for (let zoom = zoomMin; zoom <= zoomMax; zoom++) {
        // если кол-во кластеров на предыд. зуме = кол-ву точек, значит кластеризации не будет и на большем зуме
        const icon = icons.find((v) => zoom >= v.zoomMin && zoom <= v.zoomMax);
        if (zoom > zoomMin && clusters[zoom - 1].length === latlngPoints.length) {
            clusters[zoom] = [...clusters[zoom - 1]];
        } else {
            const projectedPoints = latlngPoints.map((point) => {
                return {
                    ...point,
                    projected: crs.latLngToPoint(point.latLng, zoom),
                };
            });
            clusters[zoom] = projectedPoints.map((p) => {
                return {
                    count: 1,
                    projected: p.projected,
                    cluster: [p],
                    id: p.id,
                    geometry: p.point,
                };
            });

            if (zoom === zoomMax && !clusterLastZoom) continue;

            // point intersections
            let changed = true;
            while (changed) {
                changed = false;
                let remaining = [...clusters[zoom]];
                let nextClusters = [];
                while (remaining.length > 0) {
                    const p1 = remaining[0];
                    const iconWidth = p1.cluster.length > 0 ? clusterIcon.width : icon.width;
                    const iconHeight = p1.cluster.length > 0 ? clusterIcon.height : icon.height;
                    const clusters = remaining.filter(
                        (p2) =>
                            Math.abs(p1.projected.x - p2.projected.x) <= iconWidth &&
                            Math.abs(p1.projected.y - p2.projected.y) <= iconHeight,
                    );
                    const idsMap = clusters.reduce(
                        (acc, current) => ({ ...acc, [current.id]: true }),
                        {},
                    );
                    if (clusters.length > 1) changed = true;
                    const newCluster = clusters.reduce(
                        (acc, current) => acc.concat(current.cluster),
                        [],
                    );
                    nextClusters.push({
                        cluster: newCluster,
                        count: newCluster.length,
                        projected: {
                            x:
                                newCluster.reduce((acc, current) => acc + current.projected.x, 0) /
                                newCluster.length,
                            y:
                                newCluster.reduce((acc, current) => acc + current.projected.y, 0) /
                                newCluster.length,
                        },
                        id: clusters.map((p) => p.id).join('|'),
                    });
                    remaining = remaining.filter((p) => !idsMap[p.id]);
                }
                clusters[zoom] = nextClusters;
            }
            clusters[zoom].projected = projectedPoints;
            clusters[zoom] = clusters[zoom].map((cluster) => {
                const latlng = crs.pointToLatLng(cluster.projected, zoom);
                return {
                    ...cluster,
                    ids: cluster.cluster.map((v) => v.id),
                    geometry: {
                        type: 'Point',
                        coordinates: [latlng.lng, latlng.lat],
                    },
                };
            });
        }
    }

    // define for each cluster zoom when this cluster doesn't exist
    for (let zoom = zoomMin; zoom <= zoomMax; zoom++) {
        if (clusters[zoom].length < latlngPoints.length) {
            clusters[zoom] = clusters[zoom].map((cluster) => {
                if (cluster.count > 1) {
                    let nextZoom = zoom + 1;
                    let definedZoom = 0;
                    while (!definedZoom) {
                        if (
                            !clusters[nextZoom] ||
                            !clusters[nextZoom].find((v) => v.id === cluster.id)
                        )
                            definedZoom = nextZoom;
                        nextZoom++;
                    }

                    return {
                        ...cluster,
                        nextZoom: definedZoom,
                    };
                }
                return cluster;
            });
        }
    }
    return clusters;
};

export const lineSplitter = (
    datas: {
        groups: { id: string; color: string; count: number; payload: any[] }[];
        from: any;
        to: any;
    }[],
    zoom = 18,
    minLengthPercentage = 20,
) => {
    const lines = [];
    const markers = [];
    const crs = L.CRS.EPSG4326;
    datas.forEach((data) => {
        const sumCount = data.groups.reduce((acc, current) => acc + current.count, 0);
        const projectedFrom = crs.latLngToPoint(
            L.latLng(data.from.coordinates[1], data.from.coordinates[0]),
            zoom,
        );
        const projectedTo = crs.latLngToPoint(
            L.latLng(data.to.coordinates[1], data.to.coordinates[0]),
            zoom,
        );

        const generateMarker = (lineItem, from, to) => {
            const latlng = crs.pointToLatLng(
                L.point({
                    x: (to.x + from.x) / 2,
                    y: (to.y + from.y) / 2,
                }),
                zoom,
            );
            return {
                id: lineItem.id,
                icon: {
                    default: {
                        svgParams: {
                            color: lineItem.color,
                        },
                        text: `${lineItem.count}`,
                    },
                },
                geometry: {
                    type: 'Point',
                    coordinates: [latlng.lng, latlng.lat],
                },
                params: lineItem.params,
            };
        };

        if (sumCount === 1) {
            const item = data.groups.find((v) => v.count === 1);
            const lineItem = {
                id: item.payload.map((v) => v.id).join('|'),
                geometry: {
                    type: 'LineString',
                    coordinates: [data.from.coordinates, data.to.coordinates],
                },
                line: {
                    default: {
                        weight: 2,
                        color: item.color,
                    },
                },
                color: item.color,
                count: item.count,
                params: { items: item.payload },
            };
            lines.push(lineItem);
            markers.push(generateMarker(lineItem, projectedFrom, projectedTo));
        } else if (sumCount > 1) {
            const items = data.groups.map((item) => {
                return {
                    ...item,
                    part: item.count / sumCount,
                };
            });
            let prevEndCoords = L.point({ ...projectedFrom });
            items.forEach((item) => {
                const projectedToCoordinates = L.point({
                    x: prevEndCoords.x + (projectedTo.x - projectedFrom.x) * item.part,
                    y: prevEndCoords.y + (projectedTo.y - projectedFrom.y) * item.part,
                });
                const latlngFrom = crs.pointToLatLng(prevEndCoords, zoom);
                const latlngTo = crs.pointToLatLng(projectedToCoordinates, zoom);
                const lineItem = {
                    id: item.payload.map((v) => v.id).join('|'),
                    geometry: {
                        type: 'LineString',
                        coordinates: [
                            [latlngFrom.lng, latlngFrom.lat],
                            [latlngTo.lng, latlngTo.lat],
                        ],
                    },
                    line: {
                        default: {
                            weight: 2,
                            color: item.color,
                        },
                    },
                    count: item.count,
                    color: item.color,
                    params: { items: item.payload },
                };
                lines.push(lineItem);
                markers.push(generateMarker(lineItem, prevEndCoords, projectedToCoordinates));

                prevEndCoords = L.point({ ...projectedToCoordinates });
            });
        }
    });
    return { lines, markers };
};

// response in metres
export const distance = (
    from: { lat: number; lng: number },
    to: { lat: number; lng: number },
): number => {
    return L.latLng(from).distanceTo(L.latLng(to));
};
