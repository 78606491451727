<div
    class="bazis-control"
    [class.bazis-control--focused]="isFocused"
    [class.bazis-control--dirty]="ngControl.control.value"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <label
            class="bazis-control__label"
            [class.bazis-h6]="hasTitleMajor"
            *ngIf="titleKey || title"
        >
            {{ title || t(titleKey, titleParams) }}
            <span
                color="danger"
                *ngIf="data.required"
            >
                *
            </span>
        </label>

        <div
            class="bazis-control__field-group"
            [class.bazis-control__field-group--disabled]="field.disabled"
            [class.bazis-control__field-group--focused]="isFocused"
        >
            <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                <div
                    #tooltipLeft
                    class="bazis-control__field-group__start"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                    <bazis-tooltip
                        [reference]="tooltipLeft"
                        placement="bottom-start"
                        [isStatic]="true"
                        >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                    >
                </div>
            </ng-container>
            <div class="bazis-control__field-wrapper">
                <input
                    [type]="showPass ? 'text' : 'password'"
                    class="bazis-control__field"
                    [class.bh-no-padding]="withoutInnerPadding"
                    [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                    (focus)="markAsTouched(); isFocused = true"
                    (blur)="blurField($event)"
                    [autocomplete]="autocomplete"
                    [formControl]="field"
                />
            </div>
            <div class="bazis-control__field-group__end">
                <bazis-button
                    *ngIf="!data.readonly"
                    fill="clear"
                    (click)="showPass = !showPass"
                >
                    <bazis-icon [name]="showPass ? 'eye-crossed' : 'eye'"></bazis-icon>
                </bazis-button>
            </div>
            <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                <div
                    #tooltipRight
                    class="bazis-control__field-group__end"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                    <bazis-tooltip
                        [reference]="tooltipRight"
                        placement="bottom-end"
                        [isStatic]="true"
                        >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                    >
                </div>
            </ng-container>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
    </ng-container>
</div>
