<div
    class="bazis-btn-group bazis-btn-group_h bazis-dropdown"
    *transloco="let t"
    clickOutside
    (clickOutside)="hideList()"
>
    <div
        #btnTiles
        class="bazis-btn-group__item"
    >
        <bazis-button
            fill="outline"
            (click)="showList = !showList"
        >
            <bazis-icon
                slot="icon-only"
                src="/assets/icons/other/tiles.svg"
            ></bazis-icon>
        </bazis-button>
        <bazis-tooltip
            [reference]="btnTiles"
            placement="left"
            class="bh-popper-z-index"
            [isStatic]="true"
            [offset]="[-12, 0]"
            [arrow]="false"
            >{{ t('map.tooltip.tiles') }}</bazis-tooltip
        >
    </div>
    <div
        *ngIf="showList"
        class="bazis-dropdown__body"
    >
        <div class="bazis-list bazis-list_select">
            <button
                class="bazis-item"
                *ngFor="let tile of tiles"
                [color]="tile.id === selectedTileId ? 'action' : null"
                [class.bazis-item--selected]="tile.id === selectedTileId"
                (click)="selectTile(tile)"
            >
                {{ t(tile.nameKey || 'map.tiles.' + tile.id) }}
            </button>
        </div>
    </div>
</div>
