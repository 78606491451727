import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    Self,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { TooltipSettings, ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import {
    LatLng,
    MapDraggedEvent,
    MapGeometry,
    MapIcon,
    MapLayers,
    MapLayerSettings,
    MapSettings,
} from '@bazis/map/models/map.models';
import { MARKER } from '@bazis/markers';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BazisFormService } from '@bazis/form/services/form.service';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { TranslocoService } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'bazis-input-map-point',
    templateUrl: './input-map-point.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputMapPointComponent implements ControlValueAccessor, OnInit {
    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для title
    @Input() titleParams: any = null;

    // параметры для note
    @Input() noteParams: any = null;

    // заголовок поля lat
    @Input() latTitleKey: string = null;

    // заголовок поля lng
    @Input() lngTitleKey: string = null;

    // для карты настройка иконки
    @Input() icon: MapIcon = null;

    // для карты настройка
    @Input() settings: Partial<MapSettings> = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    // to hide from user coordinate fields
    @Input() hideCoordinateFields = false;

    //для всплытия состояния
    @Output() touched = new EventEmitter();

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    // показывать ли поля ввода для координат
    @Input() showTextFields = false;

    // показывать ли значения координат с возможностью копирования (bazis-coordinates)
    @Input() showCoordinatesValue = false;

    //show star
    @Input() required$: Observable<boolean> = null;

    //show star
    @Input() precision: number = 6;

    @Input() iconColor: string = '#00A2AD';

    @Input() marker = MARKER;

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    mapSettings = new TemplateObservable(null);

    layerSettings = new TemplateObservable(null);

    centerMapOnCoordinates = new TemplateObservable(null);

    defaultLocation: LatLng = {
        lat: this.configurationService.defaultLocation[0],
        lng: this.configurationService.defaultLocation[1],
    };

    lat = new FormControl();

    lng = new FormControl();

    defaultLayerSettings: MapLayers = {
        pointLayer: {
            id: 'pointLayer',
            type: 'marker',
            hasPopup: false,
            hasTooltip: true,
            tooltipSettings: {
                isRevertActionTooltip: true,
                tooltipContent: `<span class="bh-text-nowrap">${this.translocoService.translate(
                    'form.tooltip.mapPoint',
                )}</span>`,
                className: 'leaflet-tooltip_default',
            },
            isEditable: true,
            zIndex: 500,
            objects: [],
            icon: {
                type: 'svgTpl',
                default: {
                    ...this.marker,
                    svgParams: {
                        color: this.iconColor,
                    },
                },
            },
        },
    };

    point: TemplateObservable<MapGeometry> = new TemplateObservable(null);

    ready = new TemplateObservable(false);

    mapObjectData = null;

    moveTo$ = new Subject();

    onMoveTo$ = this.moveTo$.pipe(
        debounceTime(300),
        tap(({ lat, lng }) => {
            this.lat.setValue(lat);
            this.lng.setValue(lng);
        }),
    );

    private _isSelectedByUser = false;

    constructor(
        @Self() public ngControl: NgControl,
        private configurationService: BazisConfigurationService,
        private translocoService: TranslocoService,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }
        if (this.icon) {
            this.defaultLayerSettings.pointLayer = {
                ...this.defaultLayerSettings.pointLayer,
                icon: { ...this.icon },
            };
        }
        // in geojson
        const value = this.ngControl?.control.value;

        if (!value) {
            this.getLocation();
        } else {
            this.initMap({
                lat: value.coordinates[1],
                lng: value.coordinates[0],
            });
        }

        merge(this.lat.valueChanges, this.lng.valueChanges)
            .pipe(
                debounceTime(0),
                tap((v) => {
                    const lat = (+this.lat.value).toFixed(this.precision);
                    const lng = (+this.lng.value).toFixed(this.precision);
                    this.updateMapCoordinates(lat, lng);
                    if (!+lat && !+lng && this.ngControl.control.value === null) return;
                    this.change(lat, lng);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    initMap(location: LatLng) {
        this.mapSettings.set({
            defaultLocation: location,
            ...this.settings,
        });
        this.ready.set(true);
    }

    getLocation() {
        // инициация карты до запроса браузера о предоставлении данных о местоположении
        this.initMap(this.defaultLocation);
        this.initValue();

        navigator.geolocation.getCurrentPosition(
            (location) => {
                this.defaultLocation = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude,
                };
                this.initMap(this.defaultLocation);
                this.initValue();
            },
            (e) => {
                this.initMap(this.defaultLocation);
                this.initValue();
            },
            {
                enableHighAccuracy: true,
            },
        );
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.updateLayerProperties({ isEditable: false });
            this.lat.disable();
            this.lng.disable();
        } else {
            this.updateLayerProperties({ isEditable: true });
            this.lat.enable();
            this.lng.enable();
        }
    }

    updateLayerProperties(properties: Partial<MapLayerSettings> = null) {
        this.defaultLayerSettings.pointLayer = {
            ...this.defaultLayerSettings.pointLayer,
            ...properties,
        };
        this.layerSettings.set({
            pointLayer: {
                ...this.defaultLayerSettings.pointLayer,
                objects: this.mapObjectData ? [this.mapObjectData] : [],
                updatedTime: new Date().getTime(),
            },
        });
    }

    move(value: MapDraggedEvent, skipChange = false) {
        if (!value) return;
        if (!skipChange) this._isSelectedByUser = true;
        const lat = (+value.coordinates.lat).toFixed(this.precision);
        const lng = (+value.coordinates.lng).toFixed(this.precision);
        this.lat.setValue(lat, { emitEvent: false });
        this.lng.setValue(lng, { emitEvent: false });
        if (!skipChange) {
            this.moveTo$.next({ lat, lng });
        }
        this.markAsTouched();
    }

    change(lat, lng) {
        this.onChange({
            type: 'Point',
            coordinates: [+lng, +lat],
        });
    }

    public writeValue(value: any): void {
        if (value && !value.coordinates[1] && !value.coordinates[0]) {
            value = null;
        }
        const lat = value ? (+value.coordinates[1]).toFixed(this.precision) : null;
        const lng = value ? (+value.coordinates[0]).toFixed(this.precision) : null;
        this.updateMapCoordinates(lat, lng, lat && lng ? true : false, 0.01);
        this.lat.setValue(lat, { emitEvent: false });
        this.lng.setValue(lng, { emitEvent: false });
    }

    // duration in seconds
    updateMapCoordinates(valueLat, valueLng, zoom = false, animationDuration = 0.35) {
        const lat = +valueLat || this.defaultLocation.lat;
        const lng = +valueLng || this.defaultLocation.lng;
        const notInitialCoords = +valueLng || +valueLat;

        this.mapObjectData = {
            id: 'editablePoint',
            geometry: {
                type: 'Point',
                coordinates: [lng, lat],
            },
        };

        this.centerMapOnCoordinates.set({
            lat,
            lng,
            minZoom: zoom && notInitialCoords ? 16 : null,
            animationDuration,
        });

        this.updateLayerProperties({ hasTooltip: !notInitialCoords });
    }

    initValue(): void {
        if (this._isSelectedByUser) return;

        this.move(
            {
                coordinates: { ...this.defaultLocation },
            },
            true,
        );
        this.writeValue(null);
    }

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
    }
}
