import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
    selector: 'bazis-map-my-location-control',
    templateUrl: 'my-location.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisMyLocationComponent {
    @Input()
    map: L.Map;

    constructor() {}

    ngOnInit() {
        this.map.on('locationerror', (e) => {
            console.log('LOCATION ERROR', e);
        });
        this.map.on('locationfound', (e) => {
            console.log('LOCATION FOUND', e);
        });
    }

    geolocate() {
        this.map.locate({ setView: true });
    }
}
