import { Injectable } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { EntityFormControl } from '@bazis/form/models/form.types';
import moment from 'moment';
import { FormGroup } from '@angular/forms';
import { ConfigurationService, ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import {
    BehaviorSubject,
    filter,
    map,
    merge,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalPointComponent } from '@pages/shared/components/modal-point/modal-point.component';
import { Router } from '@angular/router';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { MapGeometry } from '@bazis/map/models/map.models';
import { LoginComponent } from '@pages/entrance/login/login.component';
import { BazisScrollService } from '@bazis/shared/services/scroll.service';
import { Toast } from '@bazis/shared/models/toast.types';
import { OrderService } from '@pages/shared/services/order.service';

export type CalculatorPreorder = {
    volume: string;
    municipality: string;
    point: MapGeometry;
    address: string;
    date: string;
};

@Injectable({
    providedIn: 'root',
})
export class CalculatorService {
    form;

    formChanges$;

    isFormValid$: Observable<boolean>;

    startCalculation$ = new BehaviorSubject(false);

    calculation$: Observable<number>;

    autofill$: Observable<any>;

    price;

    volumeOptions$ = this.orderService.volumeOptions$.pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    private readonly _sessionKey = 'orderCalculator';

    constructor(
        private modalService: BazisModalService,
        private configurationService: ConfigurationService,
        private srv: BazisSrvService,
        private router: Router,
        private toastService: BazisToastService,
        private scrollService: BazisScrollService,
        private orderService: OrderService,
    ) {}

    initForm() {
        const sessionValue = this.getPreorder();

        const volume = new EntityFormControl(sessionValue?.volume || null);

        volume.$config = {
            anySettings: {
                multiple: false,
            },
        };

        const date = new EntityFormControl(sessionValue?.date || null);
        date.$config = {
            anySettings: {
                minDate: moment().add(2, 'days').hours(0).minutes(0).seconds(0).milliseconds(0),
                maxDate: moment().add(14, 'days').hours(0).minutes(0).seconds(0).milliseconds(0),
            },
        };

        this.form = new FormGroup({
            volume,
            date,
            address: new EntityFormControl(sessionValue?.address || null),
            point: new EntityFormControl(sessionValue?.point || null),
            municipality: new EntityFormControl(sessionValue?.municipality || null),
        });

        this.initObservables();
    }

    initObservables() {
        this.formChanges$ = this.form.valueChanges.pipe(
            tap((v) => {
                this.price = null;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.isFormValid$ = this._generateValidation$();

        this.calculation$ = this._generateCalculation$();

        this.autofill$ = this.volumeOptions$.pipe(
            tap((options) => {
                const availableOptions = options.filter((v) => !v.$snapshot.disabled);
                if (availableOptions.length === 1) {
                    this.form.get('volume').setValue(availableOptions[0].id);
                }
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    openLocation(e) {
        const initialValue = this.form.value.address
            ? {
                  address: this.form.value.address,
                  point: this.form.value.point,
                  municipality: this.form.value.municipality,
              }
            : null;

        const modal = this.modalService.create({
            component: ModalPointComponent,
            componentProperties: {
                titleKey: 'home.screen4.modal.title',
                initialValue,
            },
        });

        this.scrollService.stopScroll(e);

        modal.onDidDismiss().then((r) => {
            this.scrollService.startScroll(e);
            this.scrollService.scrollToPos();
            if (!r) return;
            this.form.patchValue({ ...r });
        });
    }

    createOrder(role, e) {
        sessionStorage.setItem(
            this._sessionKey,
            JSON.stringify({ ...this.form.value, price: this.price }),
        );
        if (role === ROLE.client || role === ROLE.clientOrg) {
            this.router.navigateByUrl('/bid/order/new');
        } else {
            const modal = this.modalService.create({
                component: LoginComponent,
                cssClass: 'sl-modal_clear sl-modal_entrance',
                // hasCloseIcon: false,
            });

            this.scrollService.stopScroll(e);

            modal.onDidDismiss().then(() => {
                this.scrollService.startScroll(e);
                this.scrollService.scrollToPos();
            });
        }
    }

    private _generateValidation$() {
        return merge(of(this.form.value), this.formChanges$).pipe(
            map(() => {
                if (this.form.status !== 'VALID') return false;
                const value = this.form.value;

                if (
                    !value.address ||
                    !value.point ||
                    !value.municipality ||
                    !value.date ||
                    !value.volume
                )
                    return false;

                return true;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    private _generateCalculation$() {
        return this.startCalculation$.pipe(
            filter((v) => !!v),
            switchMap(() =>
                this.srv
                    .sendFormRequest$(`order/order/proposal_preselection`, {
                        volume_id: this.form.value.volume,
                        export_date: this.form.value.date,
                        municipality: this.form.value.municipality,
                        point: this.form.value.point,
                    })
                    .pipe(
                        catchError((e) => {
                            this._showApiError(e);
                            return of(null);
                        }),
                    ),
            ),
            switchMap((createResponse: any) =>
                createResponse?.task_id
                    ? this.srv.getTaskResult$(createResponse.task_id).pipe(
                          catchError((e) => {
                              this._showApiError(e);
                              return of(null);
                          }),
                      )
                    : of(createResponse),
            ),
            map((v) => {
                this.startCalculation$.next(false);

                if (!v) return;
                if (v?.error !== undefined) {
                    const toast = {
                        titleKey: 'toast.taskError.title',
                        message: null,
                        messageKey: null,
                        type: 'error',
                    };
                    if (v?.error) {
                        toast.message = v.error;
                    } else {
                        toast.messageKey = 'toast.taskError.emptyProposalPreselection';
                    }
                    this.toastService.create(toast as Partial<Toast>);
                    this.price = null;
                    return of(null);
                }
                this.price = v.price;
                return this.price;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    private _showApiError(e) {
        this.toastService.create({
            type: 'error',
            titleKey: 'toast.apiError.title',
            messageKey: 'toast.apiError.message',
            messageParams: { message: this.srv.generateErrorMessage(e) },
        });
    }

    getPreorder(): CalculatorPreorder {
        const sessionStr = sessionStorage.getItem(this._sessionKey);
        const obj = sessionStr ? JSON.parse(sessionStr) : null;

        if (!obj) return obj;
        if (!obj.address || !obj.volume || !obj.point || !obj.municipality || !obj.date)
            return null;

        return obj;
    }

    removePreorder() {
        sessionStorage.removeItem(this._sessionKey);
    }
}
