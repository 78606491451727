import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultPopupSettings } from '@bazis/map/models/map.models';

@Component({
    selector: 'bazis-map-default-popup',
    templateUrl: 'default-popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisDefaultPopupComponent {
    @Input()
    settings: DefaultPopupSettings;

    @Output()
    action = new EventEmitter();

    constructor() {}

    click(action: string, payload = null) {
        this.action.emit({
            action,
            payload,
        });
    }
}
