import * as L from 'leaflet';

L.Canvas.include({
    _updateImg(layer) {
        const { img } = layer.options;
        const p = layer._point.round();
        p.x += img.offset.x;
        p.y += img.offset.y;
        if (img.rotate) {
            this._ctx.save();
            this._ctx.translate(p.x, p.y);
            this._ctx.rotate((img.rotate * Math.PI) / 180);
            this._ctx.drawImage(
                img.el,
                -img.size[0] / 2,
                -img.size[1] / 2,
                img.size[0],
                img.size[1],
            );
            this._ctx.restore();
        } else {
            this._ctx.drawImage(
                img.el,
                p.x - img.size[0] / 2,
                p.y - img.size[1] / 2,
                img.size[0],
                img.size[1],
            );
        }
    },
});

const angleCrds = (map, prevLatlng, latlng) => {
    if (!latlng || !prevLatlng) return 0;
    const pxStart = map.project(prevLatlng);
    const pxEnd = map.project(latlng);
    return (Math.atan2(pxStart.y - pxEnd.y, pxStart.x - pxEnd.x) / Math.PI) * 180 - 90;
};

const defaultImgOptions = {
    rotate: 0,
    size: [40, 40],
    offset: { x: 0, y: 0 },
};

export class CanvasMarkerHandler extends L.CircleMarker {
    private _width: number;

    private _height: number;

    private _latlng: L.LatLng;

    private _offset: L.Point;

    private _halfWidth: number;

    private _halfHeight: number;

    private _pxBounds: L.Bounds;

    private _point: L.Point;

    private _renderer: L.Renderer & { _updateImg: Function; _updateCanvasText: Function };

    options: any;

    constructor(latlng, options: any) {
        if (options.pane) delete options.pane;
        if (!options.radius && options.img && options.img.size) {
            options.radius = Math.ceil(Math.max(...options.img.size) / 2);
        }

        super(latlng, options);

        L.Util.setOptions(this, options);
        this.setOptions(options);
    }

    setOptions(options: any) {
        this.options = { ...this.options, ...options };
        this._width = options.img.size[0];
        this._height = options.img.size[1];
        this._halfWidth = this._width / 2;
        this._halfHeight = this._height / 2;
        this._offset = options.img.offset;
    }

    _updatePath() {
        if (!this.options.img || !this.options.img.url) return;
        if (!this.options.img.el) {
            this.options.img = { ...defaultImgOptions, ...this.options.img };
            if (!this.options.img.rotate)
                this.options.img.rotate += angleCrds(
                    this._map,
                    this.options.prevLatlng,
                    this._latlng,
                );
            const img = document.createElement('img');
            img.src = this.options.img.url;
            this.options.img.el = img;
            img.onload = () => {
                this.redraw();
            };
            img.onerror = () => {
                this.options.img = null;
            };
        } else {
            this._renderer._updateImg(this);
        }
        this._renderer._updateCanvasText(this);
    }

    _updateBounds() {
        const offsetPoint = {
            x: this._point.x + this._offset.x,
            y: this._point.y + this._offset.y,
        };

        this._pxBounds = L.bounds(
            L.point({ x: offsetPoint.x - this._halfWidth, y: offsetPoint.y - this._halfHeight }),
            L.point({ x: offsetPoint.x + this._halfWidth, y: offsetPoint.y + this._halfHeight }),
        );
    }

    // Needed by the `Canvas` renderer for interactivity
    _containsPoint(p) {
        const offsetPoint = {
            x: this._point.x + this._offset.x,
            y: this._point.y + this._offset.y,
        };
        return (
            p.x > offsetPoint.x - this._halfWidth &&
            p.y > offsetPoint.y - this._halfHeight &&
            p.x < offsetPoint.x + this._halfWidth &&
            p.y < offsetPoint.y + this._halfHeight
        );
    }
}

declare module 'leaflet' {
    type CanvasMarker = CanvasMarkerHandler;
    let CanvasMarker: typeof CanvasMarkerHandler;

    let canvasMarker: (...args: ConstructorParameters<typeof CanvasMarker>) => CanvasMarker;
}

if (typeof window.L !== 'undefined') {
    window.L.CanvasMarker = CanvasMarkerHandler;
    window.L.canvasMarker = (...args: ConstructorParameters<typeof CanvasMarkerHandler>) =>
        new CanvasMarkerHandler(...args);
}

// L.CanvasMarker = L.CircleMarker.extend({
//     initialize: function (latlng, options) {
//         L.CircleMarker.prototype.initialize.call(this, latlng, options);
//         this._width = options.img.size[0];
//         this._height = options.img.size[1];
//         this._halfWidth = this._width / 2;
//         this._halfHeight = this._height / 2;
//         this._offset = options.img.offset;
//     },
//
//     _updatePath() {
//         if (!this.options.img || !this.options.img.url) return;
//         if (!this.options.img.el) {
//             this.options.img = { ...defaultImgOptions, ...this.options.img };
//             if (!this.options.img.rotate)
//                 this.options.img.rotate += angleCrds(
//                     this._map,
//                     this.options.prevLatlng,
//                     this._latlng,
//                 );
//             const img = document.createElement('img');
//             img.src = this.options.img.url;
//             this.options.img.el = img;
//             img.onload = () => {
//                 this.redraw();
//             };
//             img.onerror = () => {
//                 this.options.img = null;
//             };
//         } else {
//             this._renderer._updateImg(this);
//         }
//     },
//
//     _updateBounds: function () {
//         const offsetPoint = {
//             x: this._point.x + this._offset.x,
//             y: this._point.y + this._offset.y,
//         };
//
//         this._pxBounds = L.bounds(
//             { x: offsetPoint.x - this._halfWidth, y: offsetPoint.y - this._halfHeight },
//             { x: offsetPoint.x + this._halfWidth, y: offsetPoint.y + this._halfHeight },
//         );
//     },
//
//     // Needed by the `Canvas` renderer for interactivity
//     _containsPoint: function (p) {
//         const offsetPoint = {
//             x: this._point.x + this._offset.x,
//             y: this._point.y + this._offset.y,
//         };
//         return (
//             p.x > offsetPoint.x - this._halfWidth &&
//             p.y > offsetPoint.y - this._halfHeight &&
//             p.x < offsetPoint.x + this._halfWidth &&
//             p.y < offsetPoint.y + this._halfHeight
//         );
//     },
// });

// L.canvasMarker = function (...opt) {
//     try {
//         const i = opt.findIndex((o) => typeof o === 'object' && o.img);
//         if (i + 1) {
//             if (!opt[i].radius && opt[i].img && opt[i].img.size)
//                 opt[i].radius = Math.ceil(Math.max(...opt[i].img.size) / 2);
//             if (opt[i].pane) delete opt[i].pane;
//         }
//     } catch (e) {}
//     return new L.CanvasMarker(...opt);
// };
