import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    Self,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { filter, map, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { merge, of, shareReplay, Subject, switchMap } from 'rxjs';
import { BazisInputDefaultComponent } from '@bazis/form/components/input-default.component';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { EntList } from '@bazis/shared/models/srv.types';
import { buildFilterStr } from '@bazis/utils';
import { distance } from '@bazis/map/services/map-utils';
@UntilDestroy()
@Component({
    selector: 'bazis-input-address',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputAddressComponent extends BazisInputDefaultComponent implements OnChanges {
    @Input()
    getMunicipalityByOktmo = true;

    @Input()
    lat;

    @Input()
    lng;

    @Input()
    onlyWithCoordinates = true;

    @Output()
    selectedLatLng = new EventEmitter();

    @Output()
    selectedMunicipality = new EventEmitter();

    protected _latLng$: Subject<boolean> = new Subject();

    protected _fromList = false;

    protected _emittedLng = null;

    protected _emittedLat = null;

    showList = false;

    _oktmo$: Subject<string> = new Subject();

    municipality$ = this._oktmo$.pipe(
        switchMap((value: string) => {
            return value
                ? this.entityService.getEntityList$('classifier/municipality', {
                      params: {
                          filter: buildFilterStr({ oktmo: value.substring(0, 5) }),
                      },
                      limit: 1,
                  })
                : of({ list: [] });
        }),
        tap((v: EntList) => {
            this.selectedMunicipality.emit(v.list.length > 0 ? v.list[0].id : null);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    listFromAddress$ = this.valueChanges$.pipe(
        filter((value) => {
            const oldFromList = this._fromList;
            this._fromList = false;
            return !oldFromList;
        }),
        switchMap((value) => {
            return value
                ? this.srv.commonGetRequest$('dadata/suggest_address', { address: value })
                : of([]);
        }),
        map((list) =>
            this.onlyWithCoordinates
                ? list.filter((item) => item.data.geo_lat && item.data.geo_lon)
                : list,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    listFromLatLng$ = this._latLng$.pipe(
        filter(() => {
            const oldFromList = this._fromList;
            this._fromList = false;
            return !oldFromList;
        }),
        switchMap(() => {
            return this.lat && this.lng
                ? this.srv.commonGetRequest$('dadata/geolocate', {
                      lat: this.lat,
                      lon: this.lng,
                  })
                : of([]);
        }),
        map((list) =>
            this.onlyWithCoordinates
                ? list.filter((item) => item.data.geo_lat && item.data.geo_lon)
                : list,
        ),
        tap((list) => {
            const nearest = list && list.length > 0 ? this._formatListValue(list[0]) : null;
            if (!nearest) return;
            const dist = distance(
                { lat: +nearest.lat, lng: +nearest.lng },
                { lat: +this.lat, lng: +this.lng },
            );
            const maxDist = this.field.value ? 20 : 1000;
            // console.log(
            //     'distance (m):',
            //     dist,
            //     'selected coords:',
            //     { lat: +this.lat, lng: +this.lng },
            //     'list coords:',
            //     { lat: +nearest.lat, lng: +nearest.lng },
            // );
            if (dist < maxDist) {
                this.selectFromList(nearest);
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    list$ = merge(this.listFromAddress$).pipe(
        map((response) => response.map((v, index) => this._formatListValue(v))),
        tap((v) => {
            this.showList = true;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        @Self() public ngControl: NgControl,
        protected cdr: ChangeDetectorRef,
        protected srv: BazisSrvService,
        protected entityService: BazisEntityService,
    ) {
        super(ngControl, cdr);
        ngControl.valueAccessor = this;
    }

    protected _formatListValue(v: any) {
        return {
            oktmo: v.data.oktmo,
            address: v.unrestricted_value,
            lat: v.data.geo_lat,
            lng: v.data.geo_lon,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if (changes.lat || changes.lng) {
            if (
                changes?.lat?.currentValue !== this._emittedLat ||
                changes?.lng?.currentValue !== this._emittedLng
            ) {
                this._latLng$.next(true);
            }
            this._emittedLng = null;
            this._emittedLat = null;
        }
    }

    selectFromList(value) {
        this._fromList = true;
        this.showList = false;
        this.field.setValue(value.address);
        this.lat = +value.lat;
        this.lng = +value.lng;
        this._emittedLng = this.lng;
        this._emittedLat = this.lat;
        this.selectedLatLng.emit({ lat: this.lat, lng: this.lng });
        if (this.getMunicipalityByOktmo) {
            this._oktmo$.next(value.oktmo);
        }
    }

    hideList() {
        if (!this.isFocused) this.showList = false;
    }

    focusField(e) {
        super.focusField(e);
        this.showList = true;
    }
}
