import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MapLayers, MapPopupInfo, MapSettings } from '@bazis/map/models/map.models';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisMapModalComponent } from '@bazis/map/components/modals/map-modal.component';

@Component({
    selector: 'bazis-map-open-modal-control',
    templateUrl: 'open-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisOpenModalComponent implements OnInit {
    @Input()
    mapSettings$: Observable<MapSettings>;

    @Input()
    mapLayers$: Observable<MapLayers>;

    @Input()
    mapPopupInfo$: Observable<MapPopupInfo>;

    constructor(private modalService: BazisModalService) {}

    ngOnInit() {}

    openModal() {
        this.modalService.create({
            component: BazisMapModalComponent,
            componentProperties: {
                mapSettings$: this.mapSettings$,
                mapLayers$: this.mapLayers$,
                mapInfo$: this.mapPopupInfo$,
            },
            modalType: 'map',
        });
    }
}
