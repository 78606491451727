import { Injectable } from '@angular/core';
import { SHARE_REPLAY_SETTINGS, SL_ORG_TIN } from './configuration.service';
import { fromEvent, shareReplay, withLatestFrom } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SwAuthService } from '@shared/services/auth.service';
import { buildFilterStr } from '@bazis/utils';
import { BazisEntityService } from '@bazis/shared/services/entity.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    systemNotifications$ = fromEvent(window, 'storage').pipe(
        filter((event: any) => event.key === 'event' && event.newValue),
        withLatestFrom(this.authService.userId$),
        tap(([event, userId]) => {
            if (!event.newValue || !userId) return;
            let value = null;
            try {
                value = JSON.parse(event.newValue);
            } catch {
                console.log('[DEV][ERROR] invalid event data');
            }
            if (!value) return;

            if (value.eventName === 'logout') {
                this.authService.afterLogout();
                this.authService.redirectToHomePage();
            }
            if (value.eventName === 'roleChanged') {
                this.authService.needUpdateUser('socket');
                this.authService.redirectToHomePage();
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    slOrgInfo$ = this.entityService
        .getEntityList$('organization.organization_info', {
            params: {
                filter: buildFilterStr({ tin: SL_ORG_TIN }),
            },
            limit: 1,
        })
        .pipe(
            map((v) => (v.list?.length > 0 ? v.list[0] : null)),
            shareReplay(),
        );

    constructor(private authService: SwAuthService, private entityService: BazisEntityService) {
        this.systemNotifications$.subscribe();
    }
}
