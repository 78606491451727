const markerTpl = `<svg width="36" height="53" viewBox="0 0 36 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5 17.7941C35.5 8.2535 27.6542 0.5 18 0.5C8.3458 0.5 0.5 8.2535 0.5 17.7941C0.5 32 18 40 18 47.5C18 40 35.5 32 35.5 17.7941Z" fill="{color}"/>
<path d="M17.5 53C24.9558 53 31 50.5376 31 47.5C31 44.4624 24.9558 42 17.5 42C10.0442 42 4 44.4624 4 47.5C4 50.5376 10.0442 53 17.5 53Z" fill="black" fill-opacity="0.03"/>
<path d="M18 52C23.5228 52 28 49.9853 28 47.5C28 45.0147 23.5228 43 18 43C12.4772 43 8 45.0147 8 47.5C8 49.9853 12.4772 52 18 52Z" fill="black" fill-opacity="0.05"/>
<path d="M18 51C21.866 51 25 49.433 25 47.5C25 45.567 21.866 44 18 44C14.134 44 11 45.567 11 47.5C11 49.433 14.134 51 18 51Z" fill="black" fill-opacity="0.05"/>
<path d="M18 50C20.7614 50 23 48.8807 23 47.5C23 46.1193 20.7614 45 18 45C15.2386 45 13 46.1193 13 47.5C13 48.8807 15.2386 50 18 50Z" fill="black" fill-opacity="0.05"/>
<path d="M18 49C19.6569 49 21 48.3284 21 47.5C21 46.6716 19.6569 46 18 46C16.3431 46 15 46.6716 15 47.5C15 48.3284 16.3431 49 18 49Z" fill="black" fill-opacity="0.1"/>
<path d="M18 48C18.5523 48 19 47.7761 19 47.5C19 47.2239 18.5523 47 18 47C17.4477 47 17 47.2239 17 47.5C17 47.7761 17.4477 48 18 48Z" fill="black" fill-opacity="0.35"/>
<path d="M18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24Z" fill="white"/>
</svg>`;

export const MARKER = {
    svgTpl: markerTpl,
    svgParams: {
        color: '#164982',
    },
    width: 36,
    height: 53,
    offset: {
        x: 0,
        y: -23,
    },
};

const largeVehicleMarkerTpl = `<svg width="57" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.2" class="st0" cx="27.5" cy="27.5" r="27.3" fill="{color}"/>
<ellipse cx="27.5" cy="27.5" rx="14.6" ry="14.6" fill="{color}"/>
<path d="M27.9,18.3L22,34.5l5.9-5.9l5.9,5.9L27.9,18.3z" fill="white"/>
</svg>`;

export const LARGE_VEHICLE_MARKER = {
    svgTpl: largeVehicleMarkerTpl,
    svgParams: {
        color: '#00BC41',
    },
    width: 55,
    height: 55,
    offset: {
        x: 0,
        y: 0,
    },
};

const mediumVehicleMarkerTpl = `<svg viewBox="0 0 30 30" width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="14.6" fill="{color}"/>
<path d="M15 6.1 9.1 22.3l5.9-6 6 5.9-6-16.1z" fill="white"/>
</svg>`;

export const MEDIUM_VEHICLE_MARKER = {
    svgTpl: mediumVehicleMarkerTpl,
    svgParams: {
        color: '#00BC41',
    },
    width: 30,
    height: 30,
    offset: {
        x: 0,
        y: 0,
    },
};
