import { Injectable } from '@angular/core';
import { combineLatestWith, filter, map } from 'rxjs/operators';
import { API_DOC_LANGS_MAP } from '@app/configuration.service';
import { BazisFaqService } from '@bazis/shared/services/faq.service';

@Injectable({
    providedIn: 'root',
})
export class SwFaqService extends BazisFaqService {
    searchListSettings$ = this._searchText$.pipe(
        filter((text) => !!text),
        combineLatestWith(this.lang$),
        map(([text, lang]) => {
            return {
                entityType: 'faq.faq_item',
                skipSchema: true,
                params: {
                    translations__question__$search: text,
                    translations__answer__$search: text,
                },
                paramGroups: [
                    {
                        group: ['translations__question__$search', 'translations__answer__$search'],
                        groupConnector: '|',
                    },
                ],
                reflectInUrl: false,
                defaultSort: 'order',
                defaultStaticFilter: 'all',
                sortSettings: [],
                staticFilters: [],
                filters: {},
            };
        }),
    );
}
