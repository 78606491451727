import { Injectable } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { EntData, EntList } from '@bazis/shared/models/srv.types';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    volumeOptions$ = this.entityService
        .getEntityList$('classifier.septic_volume', { saveSeparateItems: true })
        .pipe(
            map((v: EntList) => this.postProcessOptions(v, 'bid.order.tooltip.disabledVolume')),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

    paymentOptions$ = this.entityService
        .getEntityList$('classifier.payment_type', { saveSeparateItems: true })
        .pipe(
            map((v: EntList) => this.postProcessOptions(v, 'bid.order.tooltip.disabledPayment')),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

    constructor(
        private modalService: BazisModalService,
        private entityService: BazisEntityService,
        private srv: BazisSrvService,
    ) {}

    copyOrderEntity$(entity: EntData) {
        return this.copyOrder$(entity.$snapshot);
    }

    copyOrder$(entity: any) {
        const attributes: any = {
            address: entity.address,
            point: entity.point,

            comment: entity.comment,
            user_first_name: entity.user_first_name,
            user_last_name: entity.user_last_name,
            user_surname: entity.user_surname,
            phone: entity.phone,
            email: entity.email,
        };

        const relationships: any = {
            municipality: { data: entity.municipality },
            volume: { data: entity.volume },
        };

        if (entity.payment_type) {
            relationships.payment_type = { data: entity.payment_type };
        }

        const data = this.srv.generateEntityBody('order.order', null, attributes, relationships);
        return this.srv.createEntity$('order.order', data);
    }

    postProcessOptions(list: EntList, disabledTooltipKey: string = ''): EntData[] {
        return (list?.list || []).map((item) => {
            return {
                ...item,
                $snapshot: {
                    ...item.$snapshot,
                    disabled: !item.$snapshot.is_visible,
                    tooltipKey: !item.$snapshot.is_visible ? disabledTooltipKey : null,
                },
            };
        });
    }
}
