<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            list: list$ | async,
            municipality: municipality$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <label
            *ngIf="titleKey || title"
            class="bazis-control__label"
            [class.bazis-h6]="hasTitleMajor"
        >
            {{ title || t(titleKey, titleParams) }}
            <span
                color="danger"
                *ngIf="data.required"
            >
                *
            </span>
        </label>
        <div
            *ngIf="rows === 1 && !isVisibleField && ngControl.value"
            class="bh-hide-sm-up bh-margin-bottom-6x"
        >
            <p class="bh-margin-vertical-2x">{{ ngControl.value }}</p>
            <bazis-link
                class="sl-letter-spacing_025"
                borderStyle="none"
                (click)="showField($event)"
                >{{ t('action.change') }}</bazis-link
            >
        </div>
        <div
            class="bazis-list_control-dd-wrapper"
            [class.bh-events-none]="field.disabled"
            [class.bh-hide-sm-down]="!isVisibleField"
            clickOutside
            (clickOutside)="hideList()"
        >
            <div
                class="bazis-control"
                [class.bazis-control--focused]="isFocused"
                [class.bazis-control--dirty]="ngControl.control.value"
            >
                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="field.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <div
                        *ngIf="leftIcon"
                        class="bazis-control__field-group__start"
                    >
                        <bazis-icon [icon]="leftIcon"></bazis-icon>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                        <div
                            #tooltipLeft
                            class="bazis-control__field-group__start"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                            <bazis-tooltip
                                [reference]="tooltipLeft"
                                placement="bottom-start"
                                [isStatic]="true"
                                >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                            >
                        </div>
                    </ng-container>
                    <div
                        class="bazis-control__field-wrapper"
                        #fieldWrapRef
                    >
                        <input
                            *ngIf="rows === 1"
                            class="bazis-control__field"
                            [class.bh-no-padding]="withoutInnerPadding"
                            [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                            (focus)="focusField($event)"
                            (blur)="blurField($event)"
                            [formControl]="field"
                        />
                        <textarea
                            *ngIf="rows > 1"
                            class="bazis-control__field"
                            [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                            (focus)="focusField($event)"
                            (blur)="blurField($event)"
                            [formControl]="field"
                        ></textarea>
                    </div>
                    <div
                        *ngIf="unitKey || rightIcon || (ngControl.control.value && canEraserShow)"
                        class="bazis-control__field-group__end"
                    >
                        <bazis-button
                            *ngIf="ngControl.control.value && canEraserShow"
                            fill="clear"
                            (click)="onClear()"
                            class="bazis-eraser"
                        >
                            <bazis-icon
                                slot="icon-only"
                                name="cross-small"
                            ></bazis-icon>
                        </bazis-button>
                        <span
                            *ngIf="unitKey"
                            class="bazis-control__units"
                            [innerHTML]="t(unitKey)"
                        ></span>
                        <ng-container *ngIf="rightIcon">
                            <bazis-button
                                fill="clear"
                                #rightBtn
                                *ngIf="isRightIconClickable && !data.readonly"
                                (click)="onRightIconClick($event)"
                            >
                                <bazis-icon [icon]="rightIcon"></bazis-icon>
                            </bazis-button>
                            <bazis-icon
                                *ngIf="!isRightIconClickable"
                                [icon]="rightIcon"
                            ></bazis-icon>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                        <div
                            #tooltipRight
                            class="bazis-control__field-group__end"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                            <bazis-tooltip
                                [reference]="tooltipRight"
                                placement="bottom-end"
                                [isStatic]="true"
                                >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                            >
                        </div>
                    </ng-container>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>

                    <!-- для ошибок родительского контрола
                (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
                    <ng-content></ng-content>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ t(noteKey, noteParams) }}
                </div>
            </div>

            <div
                *ngIf="data.list && data.list.length > 0 && showList"
                class="bazis-list bazis-list_control-dd"
            >
                <div
                    *ngFor="let item of data.list"
                    class="bazis-item"
                    (click)="selectFromList(item)"
                >
                    {{ item.address }}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="listFromLatLng$ | async"></ng-container>
