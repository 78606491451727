import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { SwAuthService } from '@shared/services/auth.service';
import {
    BIC_PATTERN,
    INN_PATTERN,
    PHONE_INPUT_TRANSFORM_FN,
    PHONE_MASK_SETTINGS,
    PHONE_PATTERN,
    SHARE_REPLAY_SETTINGS,
} from '@app/configuration.service';
import { BehaviorSubject, of, shareReplay, Subject } from 'rxjs';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { EntityFormControl, EntityFormGroup } from '@bazis/form/models/form.types';
import { PasswordValidators } from '@bazis/form/validators/passwordValidators';
import { SignUpValidators } from '@bazis/form/validators/signUpValidators';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { EmailValidators } from '@bazis/form/validators/emailValidators';
import { BazisToastService } from '@bazis/shared/services/toast.service';

@Component({
    selector: 'app-modal-registration',
    templateUrl: './modal-registration.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SignUpValidators],
})
export class ModalRegistrationComponent implements OnInit {
    signUp$ = new BehaviorSubject(false);

    phoneMaskSettings = PHONE_MASK_SETTINGS;

    beforeWrite = PHONE_INPUT_TRANSFORM_FN;

    form = new EntityFormGroup({
        tin: new EntityFormControl('', {
            validators: [Validators.required, Validators.pattern(INN_PATTERN)],
            asyncValidators: [this.signUpValidators.organizationValidator()],
        }),
        roles_id: new EntityFormControl('', [Validators.required]),
        first_name: new EntityFormControl('', [Validators.required]),
        surname: new EntityFormControl(''),
        last_name: new EntityFormControl('', [Validators.required]),
        contact_phone: new EntityFormControl('', [Validators.pattern(PHONE_PATTERN)]),
        email: new EntityFormControl('', [Validators.required, EmailValidators.emailValidatorFn()]),
        //login: new EntityFormControl('', [Validators.required]),
        password: new EntityFormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            PasswordValidators.noNumbersValidatorFn(),
            PasswordValidators.noLowercaseLettersValidatorFn(),
            PasswordValidators.noUppercaseLettersValidatorFn(),
            PasswordValidators.noSpecialSymbolsValidatorFn(),
            PasswordValidators.notValidSymbolsValidatorFn(),
        ]),
    });

    validationErrorMessages = [
        'noNumbers',
        'noUppercaseLetters',
        'noLowercaseLetters',
        'noSpecialSymbols',
        'notValidSymbols',
    ].reduce((acc, current) => {
        return {
            ...acc,
            [current]: `form.error.password.${current}`,
        };
    }, {});

    required$ = of(true);

    signingUp$ = this.signUp$.pipe(
        filter((v) => !!v),
        switchMap((event) => this.authService.signUp$(this.form.value)),
        tap((r) => {
            this.signUp$.next(false);
            if (!r) return;

            this.close();
            this.router.navigateByUrl('/login/signed-up', {
                state: { value: this.form.value },
            });
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    roles$ = this.authService.signUpRoles$;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private authService: SwAuthService,
        private entityService: BazisEntityService,
        private modalService: BazisModalService,
        public signUpValidators: SignUpValidators,
        public toastService: BazisToastService,
    ) {}

    ngOnInit() {}

    close() {
        this.modalService.dismiss();
    }
}
