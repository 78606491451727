import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazisInputDefaultComponent } from './input-default.component';

@Component({
    selector: 'bazis-input-password',
    templateUrl: './input-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputPasswordComponent extends BazisInputDefaultComponent {
    @Input()
    autocomplete = 'new-password';

    showPass = false;
}
