<ng-container *transloco="let t">
    <div
        class="bazis-modal-wrap"
        *ngIf="{
            valueChanges: formChanges$ | async,
            point: point$ | async
        } as data"
    >
        <div class="bazis-modal__header_shadow">
            <h3>{{ t(titleKey) }}</h3>
        </div>

        <div
            class="bazis-modal__content"
            *ngIf="form"
        >
            <form
                [formGroup]="form"
                class="sl-form"
            >
                <app-input-address
                    titleKey="title.address"
                    formControlName="address"
                    [lng]="data.point?.lng"
                    [lat]="data.point?.lat"
                    (selectedLatLng)="updatePoint($event)"
                    (selectedMunicipality)="updateMunicipality($event)"
                ></app-input-address>

                <app-input-map-point
                    formControlName="point"
                    [hideCoordinateFields]="true"
                    [settings]="settings"
                    [marker]="MARKER"
                ></app-input-map-point>

                <div
                    class="bazis-coordinates"
                    *ngIf="form.value.point"
                >
                    <span>
                        {{ form.value.point.coordinates[1] | localNumber : '1.0-6' }}
                        &nbsp;
                        {{ form.value.point.coordinates[0] | localNumber : '1.0-6' }}
                    </span>
                </div>

                <div>
                    <app-select-search
                        titleKey="title.municipality"
                        formControlName="municipality"
                        [infiniteListTpl]="infiniteListTpl"
                        [multiple]="false"
                        [hasChangeLink]="true"
                        entityType="classifier.municipality"
                    ></app-select-search>
                    <ng-template
                        #infiniteListTpl
                        let-item="item"
                    >
                        {{ item.$snapshot?.full_name }}
                    </ng-template>
                </div>
            </form>
        </div>

        <div
            class="bazis-modal__footer"
            *ngIf="form"
        >
            <bazis-toolbar>
                <div slot="start">
                    <bazis-button
                        color="action"
                        [disabled]="
                            !form.value.municipality || !form.value.address || !form.value.point
                        "
                        (click)="save()"
                    >
                        {{ t('action.save') }}
                    </bazis-button>
                </div>
            </bazis-toolbar>
        </div>
    </div>
</ng-container>
