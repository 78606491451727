import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, take, tap } from 'rxjs/operators';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NEXT_PHONE_TRY, SwAuthService } from '@shared/services/auth.service';
import {
    DOCUMENT_LINKS,
    PHONE_INPUT_TRANSFORM_FN,
    PHONE_MASK_SETTINGS,
    PHONE_PATTERN,
    SHARE_REPLAY_SETTINGS,
} from '@app/configuration.service';
import { BehaviorSubject, of, shareReplay } from 'rxjs';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { ModalSignatureComponent } from '@pages/entrance/components/modal-signature.component';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { ModalRegistrationComponent } from '@pages/entrance/components/modal-registration.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    showForm = false;

    privacyPolicy = DOCUMENT_LINKS.privacyPolicy;

    userAgreement = DOCUMENT_LINKS.userAgreement;

    userType = new FormControl(localStorage.getItem('loginUserType') || 'userIndividual');

    authing$ = this.authService.authing$;

    userTypeOptions = [
        {
            id: 'userIndividual',
            nameKey: 'login.userIndividual',
        },
        {
            id: 'userOrganization',
            nameKey: 'login.userOrganization',
        },
    ];

    userTypeChanges$ = this.userType.valueChanges.pipe(
        tap((value) => {
            localStorage.setItem('loginUserType', value);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    required$ = of(true);

    authTypesToTabsMap = {
        password: 'userOrganization',
        esia: 'userOrganization',
        cert: 'userOrganization',
        phone: 'userIndividual',
    };

    authTypes$ = this.authService.getAuthTypes$().pipe(
        map((v) => {
            return {
                ...v,
                phone: true,
                cert: true,
            };
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    notValidAuth$ = new BehaviorSubject(false);

    sendPhoneCodeError$ = new BehaviorSubject(null);

    showPass = false;

    validationErrorMessages = {
        notValidAuth: 'form.error.notValidAuth',
        authUserBlocked: 'form.error.authUserBlocked',
        unconfirmedEmail: 'form.error.unconfirmedEmail',
    };

    errorKey = '';

    showPhoneCodeComponent = false;

    phoneMaskSettings = PHONE_MASK_SETTINGS;

    phoneForm = new FormGroup({
        phone: new FormControl('', [Validators.pattern(PHONE_PATTERN)]),
    });

    phoneInputTransform = PHONE_INPUT_TRANSFORM_FN;

    loginForm = new FormGroup({
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    codeForm = new FormGroup({
        code: new FormControl(''),
    });

    timer$ = this.authService.timer$;

    pinSent$ = this.authService.pinSent$.pipe(
        tap((v) => {
            if (v === undefined) return;
            this.showPhoneCodeComponent = !!v;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    nextPhoneTry = NEXT_PHONE_TRY;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private authService: SwAuthService,
        private modalService: BazisModalService,
    ) {}

    ngOnInit() {
        this.loginForm.updateValueAndValidity();
    }

    login(f: FormGroup) {
        this.notValidAuth$.next(false);
        this.authService
            .authByPass$(f.value.username, f.value.password)
            .pipe(
                take(1),
                tap(() => {
                    const url = this.route.snapshot.queryParamMap.get('returnUrl');
                    if (url && url !== '/login') {
                        this.router.navigate([url], { replaceUrl: true });
                    }
                }),
                catchError((e) => {
                    if (e.error) {
                        if (e.error.errors) {
                            const errs = e.error.errors;
                            errs.forEach((err) => {
                                if (err.code === 'USERNAME_PASSWORD_ERROR') {
                                    this.errorKey = 'notValidAuth';
                                    this.notValidAuth$.next(true);
                                }
                                if (err.code === 'DELETED_USER_ERROR') {
                                    this.errorKey = 'authUserBlocked';
                                    this.notValidAuth$.next(true);
                                }
                                if (err.code === 'REGISTRATION_ERROR') {
                                    this.errorKey = 'unconfirmedEmail';
                                    this.notValidAuth$.next(true);
                                }
                            });
                        }
                    }
                    console.log(`[AUTH][ERROR]`, e);
                    return of(e);
                }),
            )
            .subscribe();
    }

    esiaLogin() {
        this.authService.esiaAuth();
    }

    authBySign() {
        this.modalService.create({
            component: ModalSignatureComponent,
        });
    }

    changeNumber() {
        this.phoneForm.get('phone').setValue('');
        this.codeForm.get('code').setValue('');
        this.showPhoneCodeComponent = false;
    }

    sendCode() {
        this.codeForm.get('code').setValue('');
        this.sendPhoneCodeError$.next(null);
        this.authService.sendCode(this.phoneForm.get('phone').value);
    }

    authByPhone() {
        this.sendPhoneCodeError$.next(null);
        this.authService
            .authByPhone$({
                phone: this.phoneForm.get('phone').value,
                code: this.codeForm.get('code').value,
            })
            .pipe(
                take(1),
                catchError((e) => {
                    if (e.error) {
                        if (e.error.errors) {
                            const errs = e.error.errors;
                            const text = [];
                            errs.forEach((err) => {
                                text.push(err.detail);
                            });
                            this.sendPhoneCodeError$.next(text.join(' '));
                            this.codeForm.get('code').setValue('');
                        }
                    }
                    console.log(`[AUTH][ERROR]`, e);
                    return of(e);
                }),
            )
            .subscribe();
    }

    signUp() {
        this.modalService.create({
            component: ModalRegistrationComponent,
        });
    }
}
