import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { MapGeometry, MapLayers } from '@bazis/map/models/map.models';
import { FormControl, FormGroup } from '@angular/forms';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { MARKER } from '@app/markers';
import { TranslocoService } from '@jsverse/transloco';
import { ConfigurationService, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { EntityFormControl, EntityFormGroup } from '@bazis/form/models/form.types';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { shareReplay } from 'rxjs';

@Component({
    selector: 'app-modal-point',
    templateUrl: './modal-point.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPointComponent implements OnInit {
    @Input() titleKey: string;

    @Input() initialValue: { address: string; point: string; municipality: string };

    protected readonly MARKER = MARKER;

    form;

    formChanges$;

    point$;

    settings = this.configurationService.mapDefaultSettings;

    constructor(
        private modalService: BazisModalService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            address: new FormControl(this.initialValue?.address),
            point: new FormControl(this.initialValue?.point),
            municipality: new FormControl(this.initialValue?.municipality),
        });
        this.formChanges$ = this.form.valueChanges;

        this.point$ = this.form.get('point').valueChanges.pipe(
            debounceTime(100),
            map((point: MapGeometry) => {
                if (!point) {
                    return { lat: null, lng: null };
                }
                return {
                    lat: point.coordinates[1],
                    lng: point.coordinates[0],
                };
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    close() {
        this.modalService.dismiss();
    }

    save() {
        this.modalService.dismiss({ ...this.form.value });
    }

    updatePoint(point) {
        this.form.get('point').setValue({
            type: 'Point',
            coordinates: [point.lng, point.lat],
        });
    }

    updateMunicipality(municipality) {
        this.form.get('municipality').setValue(municipality);
    }
}
