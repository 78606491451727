<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            optionList: options$ | async,
            disabled: disabled.$ | async,
            values: values.$ | async
        } as data"
    >
        <div
            *ngIf="ngControl.control; let control"
            class="bazis-control"
        >
            <div *ngIf="titleKey">
                <label
                    class="bazis-control__label"
                    [class.bh-font-normal]="!hasLabelMajor"
                >
                    {{ t(titleKey, titleParams) }}
                    <span
                        color="danger"
                        *ngIf="data.required"
                    >
                        *
                    </span>
                </label>
                <span
                    *ngIf="
                        control['$config']?.modality === 'required' &&
                        (!control.value || control.value.length === 0)
                    "
                >
                    *<small *ngIf="control.touched && control.hasError('required')">
                        обязательно
                    </small>
                </span>
                <span
                    *ngIf="
                        (!control.value || control.value.length === 0) &&
                        control['$config']?.modality === 'expected'
                    "
                >
                    *<small *ngIf="control.touched"> обязательно</small>
                </span>
            </div>

            <div class="bazis-btn-group">
                <div
                    *ngFor="let option of data.optionList"
                    class="bazis-btn-group__item"
                    #tooltipTarget
                >
                    <bazis-button
                        [disabled]="data.disabled || option.$snapshot.disabled"
                        [active]="data.values.indexOf(option.id) > -1"
                        (click)="toggleValue(option.id)"
                    >
                        <ng-container *ngIf="option.$snapshot.name">
                            {{ option.$snapshot.name }}
                        </ng-container>
                        <ng-container *ngIf="option.$snapshot.nameKey">
                            {{ t(option.$snapshot.nameKey) }}
                        </ng-container>

                        <bazis-icon
                            *ngIf="option.$snapshot.tooltipKey || option.$snapshot.tooltip"
                            slot="end"
                            name="interrogation"
                        ></bazis-icon>
                    </bazis-button>

                    <bazis-tooltip
                        *ngIf="option.$snapshot.tooltipKey || option.$snapshot.tooltip"
                        class="bh-popper-z-index"
                        [reference]="tooltipTarget"
                        placement="bottom-end"
                    >
                        {{
                            option.$snapshot.tooltipKey
                                ? t(option.$snapshot.tooltipKey, option.$snapshot.tooltipParams)
                                : option.$snapshot.tooltip
                        }}
                    </bazis-tooltip>
                </div>
            </div>

            <div class="bazis-control__error">
                <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
            </div>
            <div
                *ngIf="noteKey"
                class="bazis-control__note"
            >
                {{ t(noteKey) }}
            </div>
        </div>
    </ng-container>
</ng-container>
