import { MapLayerSettings } from '@bazis/map/models/map.models';
import * as L from 'leaflet';

export abstract class PolygonClass {
    static processLayerSettings(layer: MapLayerSettings) {
        ['default', 'selected'].forEach((layerState) => {
            if (layerState !== 'default' && !layer.polygon[layerState]) return;

            layer.polygon[layerState] = {
                ...layer.polygon.default,
                ...layer.polygon[layerState],
            };

            layer[`${layerState}Options`] = layer.polygon[layerState];
        });
        return layer;
    }

    static drawPolygon(layer: MapLayerSettings, featureGroup: L.FeatureGroup, renderer) {
        const objects = layer.objects;
        objects.forEach((object) => {
            const polygon = L.polygon(
                object.coordinates as L.LatLngTuple[][] | L.LatLngTuple[],
                {
                    ...layer.polygon.default,
                    ...object?.polygon?.default,
                    bubblingMouseEvents: false,
                    interactive: layer.isInteractive === undefined ? true : layer.isInteractive,
                    renderer,
                    properties: {
                        id: object.id,
                        popupParams: {
                            id: object.id,
                            layerId: layer.id,
                            ...object.params,
                        },
                    },
                } as L.PolylineOptions,
            );
            polygon.addTo(featureGroup);
        });
    }
}
