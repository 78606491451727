import { MapLayerSettings } from '@bazis/map/models/map.models';
import * as L from 'leaflet';
import '@bazis/map/plugins/canvas-marker';
import '@bazis/map/plugins/path-draggable';

export abstract class CanvasMarkerClass {
    static generateUrlForSvg(tpl: string, svgParams: any = {}) {
        Object.keys(svgParams).forEach((paramName) => {
            const regexp = new RegExp(`{${paramName}}`, 'g');
            tpl = tpl.replace(regexp, svgParams[paramName]);
        });
        const base64 = btoa(unescape(encodeURIComponent(tpl)));
        return `data:image/svg+xml;base64,${base64}`;
    }

    static generateMarkerOptions(params) {
        return {
            radius: Math.max(params.width, params.height) / 2,
            img: {
                url: params.url,
                size: [params.width, params.height],
                offset: params.offset,
            },
            text: params.text
                ? {
                      value: params.text,
                      align: params.textAlign,
                      baseLine: params.textBaseLine,
                      color: params.textColor,
                      fontWeight: params.fontWeight,
                      fontSize: params.fontSize,
                      fontSizeUnits: params.fontSizeUnits,
                      fontFamily: params.fontFamily,
                  }
                : null,
        };
    }

    static processLayerSettings(layer: MapLayerSettings, zoomMin = undefined, zoomMax = undefined) {
        const process = (source, zoomLevel = null) => {
            ['default', 'selected', 'cluster'].forEach((layerState) => {
                if (layerState !== 'default' && !source[layerState]) return;
                source[layerState] = {
                    ...source.default,
                    ...source[layerState],
                };

                if (source.type !== 'svgTpl') return;

                source[layerState].url = this.generateUrlForSvg(
                    source[layerState].svgTpl,
                    source[layerState].svgParams,
                );
                if (!layer[`${layerState}Options`]) layer[`${layerState}Options`] = {};

                if (zoomLevel) {
                    layer[`${layerState}Options`][zoomLevel] = this.generateMarkerOptions(
                        source[layerState],
                    );
                } else {
                    layer[`${layerState}Options`] = this.generateMarkerOptions(source[layerState]);
                }
            });
        };
        if (layer.zoomIconSettings && !layer.zoomIcon && zoomMin !== undefined && zoomMax) {
            layer.zoomIcon = {};
            for (let zoom = zoomMin; zoom <= zoomMax; zoom++) {
                const icon = layer.zoomIconSettings.find(
                    (v) => zoom >= v.zoomMin && zoom <= v.zoomMax,
                );
                layer.zoomIcon[zoom] = icon?.icon;
            }
        }
        if (layer.zoomIcon) {
            Object.keys(layer.zoomIcon).forEach((zoomLevel) => {
                process(layer.zoomIcon[zoomLevel], zoomLevel);
            });
        } else {
            process(layer.icon);
        }
        return layer;
    }

    static drawMarkers(
        layer: MapLayerSettings,
        featureGroup: L.FeatureGroup,
        renderer,
        zoom = null,
    ) {
        const objects =
            zoom !== null && layer.zoomObjects ? layer.zoomObjects[zoom] : layer.objects;
        objects.forEach((object) => {
            const [defaultObjectIcon, selectedObjectIcon] = ['default', 'selected'].map(
                (objectState) => {
                    if (!object.icon?.[objectState] || object.count > 1) return null;

                    const layerIcon = zoom && layer.zoomIcon ? layer.zoomIcon[zoom] : layer.icon;

                    const icon = {
                        ...layerIcon.default,
                        ...object.icon?.default,
                        ...layerIcon[objectState],
                        ...object.icon?.[objectState],
                    };
                    return object.icon
                        ? {
                              ...icon,
                              url: this.generateUrlForSvg(icon.svgTpl, icon.svgParams),
                          }
                        : icon;
                },
            );

            // если кластер и есть clusterOptions
            //  если есть зависимость от зума - генерация иконки с текстом от зума
            //  если нет зависимости от зума - генерация иконки с текстом
            // иначе
            //  если есть иконка у объекта кастомная - рисуем ее
            //  иначе
            //      если есть зависимость от зума - рисуем иконку из зума
            //      иначе - рисуем иконку дефолтную
            const defaultOptions =
                object.count > 1 && layer.clusterOptions
                    ? layer.zoomIcon
                        ? this.generateMarkerOptions({
                              ...layer.zoomIcon[zoom].cluster,
                              text: layer.hideClusterCount ? null : object.count,
                          })
                        : layer.hideClusterCount
                        ? layer.clusterOptions
                        : this.generateMarkerOptions({
                              ...layer.icon.cluster,
                              text: object.count,
                          })
                    : defaultObjectIcon
                    ? this.generateMarkerOptions(defaultObjectIcon)
                    : layer.zoomIcon
                    ? layer.defaultOptions[zoom]
                    : layer.defaultOptions;

            const selectedOptions = object.icon?.selected
                ? this.generateMarkerOptions(selectedObjectIcon)
                : layer.zoomIcon
                ? layer.selectedOptions
                    ? layer.selectedOptions[zoom] || null
                    : null
                : layer.selectedOptions || null; //was null TODO: check

            const defaultWithRotation = {
                ...defaultOptions,
                img: {
                    ...defaultOptions.img,
                    rotate: object.rotate || 0,
                },
            };
            const selectedWithRotation = selectedOptions
                ? {
                      ...selectedOptions,
                      img: {
                          ...selectedOptions.img,
                          rotate: object.rotate || 0,
                      },
                  }
                : null;
            const properties = {
                renderer,
                bubblingMouseEvents: false,
                ...defaultWithRotation,
                draggable: layer.isEditable,
                interactive: layer.isInteractive === undefined ? true : layer.isInteractive,
                properties: {
                    id: object.id,
                    defaultOptions: object.icon?.default ? { ...defaultWithRotation } : null,
                    selectedOptions: object.icon?.selected ? { ...selectedWithRotation } : null,
                    nextZoom: object.nextZoom,
                    count: object.count,
                    popupParams: {
                        id: object.id,
                        layerId: layer.id,
                        ...object.params,
                    },
                    tooltipParams: layer.hasTooltip
                        ? {
                              ...object.params,
                          }
                        : null,
                },
            };

            const marker = L.canvasMarker(object.coordinates, properties);
            marker.addTo(featureGroup);

            if (!layer.isEditable) return;

            marker.on('add', (e) => {
                if (layer.tooltipSettings.isRevertActionTooltip) {
                    setTimeout(() => {
                        featureGroup.fire('mouseout', { layer: e.target });
                    });
                }
            });
            marker.on('drag', (e) => {
                featureGroup.fire('drag', {
                    layerId: layer.id,
                    objectId: e.target.options.properties.id,
                    coordinates: e.target.getLatLng(),
                });
            });
            marker.on('dragstart', (e) => {
                featureGroup.fire('dragstart');
            });
            marker.on('dragend', (e) => {
                featureGroup.fire('dragend');
            });
        });
    }
}
