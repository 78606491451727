<ng-container *transloco="let t">
    <div
        class="bazis-modal-wrap"
        *ngIf="{
            certificates: certificates$ | async,
            auth: auth$ | async,
            isProcessing: isProcessing$ | async
        } as data"
    >
        <div class="bazis-modal__header_shadow">
            <h4>{{ t('login.signEntrance') }}</h4>
        </div>

        <div class="bazis-modal__content">
            <app-crypto-certificates
                *ngIf="!pluginError"
                [(certificate)]="certificate"
                [certificates$]="certificates$"
            ></app-crypto-certificates>

            <app-crypto-info *ngIf="pluginError"></app-crypto-info>
        </div>

        <div
            class="bazis-modal__footer"
            [class.bh-hide-sm-down]="!data.certificates?.length || pluginError"
        >
            <bazis-toolbar class="bh-hide-sm-down">
                <div slot="start">
                    <bazis-button
                        fill="clear"
                        color="action"
                        (click)="close()"
                        [disabled]="data.isProcessing"
                    >
                        {{ t('action.close') }}
                    </bazis-button>
                </div>
                <div
                    slot="end"
                    *ngIf="data.certificates?.length && !pluginError"
                >
                    <div class="bh-align-items-center bh-line-elements">
                        <bazis-button
                            color="action"
                            [disabled]="!certificate || data.isProcessing"
                            (click)="save$.next(true)"
                        >
                            {{ t('login.enter') }}
                        </bazis-button>
                    </div>
                </div>
            </bazis-toolbar>

            <bazis-button
                color="action"
                class="bh-hide-sm-up"
                [disabled]="!certificate || data.isProcessing"
                (click)="save$.next(true)"
            >
                {{ t('login.enter') }}
            </bazis-button>
        </div>
    </div>
</ng-container>
