import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as L from 'leaflet';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';

@Component({
    selector: 'bazis-map-zoom-control',
    templateUrl: 'zoom.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisZoomComponent {
    @Input()
    map: L.Map;

    zoomMaxExceeded = new TemplateObservable(false);

    zoomMinExceeded = new TemplateObservable(false);

    constructor() {}

    ngOnInit() {
        this.map.on('zoomend', () => {
            this.updateBtnState();
        });
    }

    zoomIn() {
        this.map.zoomIn();
        this.updateBtnState();
    }

    zoomOut() {
        this.map.zoomOut();
        this.updateBtnState();
    }

    updateBtnState() {
        const zoom = this.map.getZoom();
        this.zoomMinExceeded.set(this.map.getMinZoom() === zoom);
        this.zoomMaxExceeded.set(this.map.getMaxZoom() === zoom);
    }
}
