<ng-container [ngSwitch]="type">
    <bazis-button
        *ngSwitchCase="'button'"
        fill="clear"
        [href]="href"
        [target]="target"
    >
        <bazis-icon
            slot="icon-only"
            src="/assets/icons/other/open.svg"
        ></bazis-icon>
    </bazis-button>

    <bazis-link
        *ngSwitchCase="'link'"
        [size]="linkSize === 'medium' ? 'm' : linkSize === 'small' ? 's' : ''"
        borderStyle="none"
        [href]="href"
        [target]="target"
    >
        <ng-content></ng-content>
        <bazis-icon
            slot="end"
            [size]="linkSize === 'medium' ? 's' : 'l'"
            src="/assets/icons/other/open.svg"
        ></bazis-icon>
    </bazis-link>

    <bazis-icon
        *ngSwitchCase="'icon'"
        src="/assets/icons/other/open.svg"
        size="xs"
        class="bh-pointer bazis-text_placeholder"
        [routerLink]="href"
        [target]="target"
    ></bazis-icon>
</ng-container>
