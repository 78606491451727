import { Inject, Injectable } from '@angular/core';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import {
    combineLatest,
    merge,
    of,
    pairwise,
    shareReplay,
    Subject,
    switchMap,
    throwError,
    timer,
    withLatestFrom,
} from 'rxjs';
import {
    AUTH_PAGE_URL,
    ROLE,
    ROLE_ACCOUNT_TYPE,
    SHARE_REPLAY_SETTINGS,
} from '@app/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { catchError, debounceTime, filter, map, startWith, take, tap } from 'rxjs/operators';
import { EntList } from '@bazis/shared/models/srv.types';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisStorageService } from '@bazis/shared/services/storage.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BazisCryptoService } from '@bazis/signature/crypto/crypto.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { CalculatorService } from '@pages/shared/services/calculator.service';

export const NEXT_PHONE_TRY = 60;

@Injectable({
    providedIn: 'root',
})
export class SwAuthService extends BazisAuthService {
    private _isFirstRedirect = true;

    updateAgencyContract$ = new Subject();

    agencyContract$ = merge(this.updateAgencyContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && ROLE_ACCOUNT_TYPE[role] === 'executor'
                ? this.srv.fetchAllEntities$('long_term.agency_contract', '', {
                      filter: buildFilterStr({ org_owner: organizationId }),
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('long_term.agency_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    defaultPageRedirect$ = this.role$.pipe(
        debounceTime(0),
        withLatestFrom(this.user$),
        startWith([null, null]),
        pairwise(),
        filter(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            return prevUser?.id !== currentUser?.id;
        }),
        tap(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];

            const regexp = /^\/(faq|about|legal\-documents){1}(\/.*)*$/;
            if (
                currentUser &&
                !currentUser.$snapshot.raw_password &&
                !regexp.test(location.pathname) &&
                currentUser.$snapshot.created_type_auth !== 'cert' &&
                currentUser.id !== prevUser?.id
            ) {
                this.router.navigate(['/login/init-password']);
                this._isFirstRedirect = false;
                return;
            }
            if (location.pathname.indexOf(AUTH_PAGE_URL) > -1 && !currentUser) return;
            this.redirectToDefaultPage(currentRole, currentUser);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    requestRolesForCompanyUser$ = combineLatest([this.roles$, this.role$]).pipe(
        map(([roles, role]) => {
            roles = roles.filter((v) => !v.$snapshot.is_system);
            if (role === ROLE.systemOperator) return roles;
            return roles.filter((v) => v.$snapshot.slug !== ROLE.director);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    private _number$ = new Subject();

    pinSent$ = this._number$.pipe(
        tap(() => {
            this.authing$.next(true);
        }),
        switchMap((phone) => {
            return this.srv
                .sendFormRequest$('authing/pin/request', {
                    phone: this._clrPhone(phone),
                })
                .pipe(
                    startWith(undefined),
                    tap((v) => {
                        if (v !== undefined) {
                            this.authing$.next(false);
                        }
                    }),
                    catchError((e) => {
                        this.toastService.create({
                            type: 'error',
                            titleKey: 'toast.sendPhoneCodeError.title',
                            messageKey: 'toast.sendPhoneCodeError.message',
                            messageParams: { message: this.srv.generateErrorMessage(e) },
                        });
                        this.authing$.next(false);
                        return of(null);
                    }),
                );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    timer$ = this.pinSent$.pipe(
        filter((v) => !!v),
        switchMap((v) => timer(0, 1000).pipe(take(NEXT_PHONE_TRY + 1))),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // redirect to default page
    redirectToDefaultPage(role = null, user) {
        switch (role) {
            case ROLE.anonymous:
            case ROLE.base:
                // do not redirect from public pages to user-initial
                const regexp = /^\/(faq|about|legal\-documents){1}(\/.*)*$/;
                if (!regexp.test(location.pathname)) {
                    this.router.navigate(['/user-initial']);
                }
                break;
            case ROLE.client:
            case ROLE.clientOrg:
                if (this.calculatorService.getPreorder()) {
                    this.router.navigate(['/bid/order/new']);
                    break;
                }
            default:
                if (location.pathname.indexOf('access-denied') > -1 && !this._isFirstRedirect) {
                    this.router.navigate(['/']);
                } else if (
                    location.pathname.indexOf(AUTH_PAGE_URL) > -1
                    // to prevent redirect on home page when first entered page was access - denied
                ) {
                    if (user.$snapshot.roles.length === 1) this.router.navigate(['/']);
                    else this.router.navigate(['/login/select-role']);
                }
        }
        this._isFirstRedirect = false;
    }

    authByPhone$(data: { code: string; phone: string }) {
        this.authing$.next(true);
        return this.srv
            .sendFormRequest$('authing/pin/auth', {
                phone: this._clrPhone(data.phone),
                pin: data.code,
            })
            .pipe(
                tap((authResult) => {
                    this.calculateToken(authResult);
                }),
                map((authResult) => authResult),
                catchError((e) => {
                    this.authing$.next(false);
                    return throwError(e);
                }),
            );
    }

    sendCode(phone) {
        this._number$.next(phone);
    }

    private _clrPhone(phone) {
        //return phone.replace(/\(|\)|\-|\s/g, '');
        return phone;
    }

    constructor(
        protected entityService: BazisEntityService,
        protected storageService: BazisStorageService,
        protected srv: BazisSrvService,
        protected http: HttpClient,
        protected router: Router,
        protected cookieService: CookieService,
        protected cryptoService: BazisCryptoService,
        protected modalService: BazisModalService,
        protected toastService: BazisToastService,
        protected calculatorService: CalculatorService,
        @Inject('ROLE') public role: { [index: string]: string } = {},
        @Inject('ROLE_ACCOUNT_TYPE') public roleAccountType = {},
    ) {
        super(
            entityService,
            storageService,
            srv,
            http,
            router,
            cookieService,
            cryptoService,
            modalService,
            toastService,
        );
    }
}
