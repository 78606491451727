import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BazisInputMapPointComponent } from '@bazis/form/components/input-map-point.component';
@UntilDestroy()
@Component({
    selector: 'app-input-map-point',
    templateUrl: './input-map-point.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputMapPointComponent
    extends BazisInputMapPointComponent
    implements ControlValueAccessor, AfterViewInit
{
    ngAfterViewInit(): void {
        this.defaultLayerSettings.pointLayer.icon.default.svgParams.color = '#FFCC00';
    }
}
