import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { BazisInputSymbolComponent } from '@bazis/form/components/input-symbol.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-input-symbol',
    templateUrl: './input-symbol.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSymbolComponent
    extends BazisInputSymbolComponent
    implements ControlValueAccessor, OnChanges {}
