import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { Observable } from 'rxjs';
import { MapLayers, MapPopupInfo, MapSettings } from '@bazis/map/models/map.models';

@Component({
    selector: 'bazis-map-modal',
    templateUrl: './map-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisMapModalComponent implements OnDestroy, OnInit {
    @Input()
    mapSettings$: Observable<MapSettings>;

    @Input()
    mapLayers$: Observable<MapLayers>;

    @Input()
    mapInfo$: Observable<MapPopupInfo>;

    constructor(private modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
