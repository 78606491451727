import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-open-entity',
    templateUrl: './open-entity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenEntityComponent {
    @Input() href: string = '';

    @Input() target: string = '_self';

    @Input() type: 'button' | 'link' | 'icon' = 'button';

    @Input() linkSize: 'small' | 'medium' | 'large' = 'medium';
}
