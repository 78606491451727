const markerTpl = `<svg width="36" height="53" viewBox="0 0 36 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5 17.7941C35.5 8.2535 27.6542 0.5 18 0.5C8.3458 0.5 0.5 8.2535 0.5 17.7941C0.5 32 18 40 18 47.5C18 40 35.5 32 35.5 17.7941Z" fill="{color}"/>
<path d="M17.5 53C24.9558 53 31 50.5376 31 47.5C31 44.4624 24.9558 42 17.5 42C10.0442 42 4 44.4624 4 47.5C4 50.5376 10.0442 53 17.5 53Z" fill="black" fill-opacity="0.03"/>
<path d="M18 52C23.5228 52 28 49.9853 28 47.5C28 45.0147 23.5228 43 18 43C12.4772 43 8 45.0147 8 47.5C8 49.9853 12.4772 52 18 52Z" fill="black" fill-opacity="0.05"/>
<path d="M18 51C21.866 51 25 49.433 25 47.5C25 45.567 21.866 44 18 44C14.134 44 11 45.567 11 47.5C11 49.433 14.134 51 18 51Z" fill="black" fill-opacity="0.05"/>
<path d="M18 50C20.7614 50 23 48.8807 23 47.5C23 46.1193 20.7614 45 18 45C15.2386 45 13 46.1193 13 47.5C13 48.8807 15.2386 50 18 50Z" fill="black" fill-opacity="0.05"/>
<path d="M18 49C19.6569 49 21 48.3284 21 47.5C21 46.6716 19.6569 46 18 46C16.3431 46 15 46.6716 15 47.5C15 48.3284 16.3431 49 18 49Z" fill="black" fill-opacity="0.1"/>
<path d="M18 48C18.5523 48 19 47.7761 19 47.5C19 47.2239 18.5523 47 18 47C17.4477 47 17 47.2239 17 47.5C17 47.7761 17.4477 48 18 48Z" fill="black" fill-opacity="0.35"/>
<path d="M18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24Z" fill="white"/>
</svg>`;

export const MARKER = {
    svgTpl: markerTpl,
    svgParams: {
        color: '#FFCC00',
    },
    width: 36,
    height: 53,
    offset: {
        x: 0,
        y: -23,
    },
};

const circleMarkerTpl = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="{color}"/>
<path d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z" fill="white"/>
<path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" fill="{color}"/>
</svg>`;

export const CIRCLE_MARKER = {
    svgTpl: circleMarkerTpl,
    svgParams: {
        color: '#3356D7',
    },
    width: 32,
    height: 32,
    offset: {
        x: 0,
        y: 0,
    },
};

export const TEXT_CIRCLE_MARKER = {
    ...CIRCLE_MARKER,
    fontWeight: '500',
    fontSize: 14,
    fontSizeUnits: 'px',
    fontFamily: 'Gordita, Verdana',
    textAlign: 'center',
    textBaseLine: 'middle',
    textColor: '#fff',
};

const userLocationMarkerTpl = `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.62695 17C14.0452 17 17.627 13.4183 17.627 9C17.627 4.58172 14.0452 1 9.62695 1C5.20867 1 1.62695 4.58172 1.62695 9C1.62695 13.4183 5.20867 17 9.62695 17Z" fill="#4285F4" stroke="white" stroke-width="2"/>
</svg>`;

export const USER_LOCATION_MARKER = {
    svgTpl: userLocationMarkerTpl,
    width: 19,
    height: 18,
    offset: {
        x: 0,
        y: 0,
    },
};

const carrierTaskObjectMarkerTpl = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 13C11.3137 13 14 10.3137 14 7C14 3.68629 11.3137 1 8 1C4.68629 1 2 3.68629 2 7C2 10.3137 4.68629 13 8 13Z" fill="{color}"/>
<path d="M8 12.25C10.8995 12.25 13.25 9.8995 13.25 7C13.25 4.10051 10.8995 1.75 8 1.75C5.10051 1.75 2.75 4.10051 2.75 7C2.75 9.8995 5.10051 12.25 8 12.25Z" fill="white"/>
<path d="M8 11.5C10.4853 11.5 12.5 9.48528 12.5 7C12.5 4.51472 10.4853 2.5 8 2.5C5.51472 2.5 3.5 4.51472 3.5 7C3.5 9.48528 5.51472 11.5 8 11.5Z" fill="{color}"/>
</svg>
`;

export const CARRIER_TASK_OBJECT_MARKER = {
    svgTpl: carrierTaskObjectMarkerTpl,
    svgParams: {
        color: '#3356D7',
    },
    width: 16,
    height: 16,
    offset: {
        x: 0,
        y: 0,
    },
};

const reportMarkerTpl = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6429 40.0017C24.9685 40.0017 29.2857 38.2428 29.2857 36.0731C29.2857 33.9034 24.9685 32.1445 19.6429 32.1445C14.3173 32.1445 10 33.9034 10 36.0731C10 38.2428 14.3173 40.0017 19.6429 40.0017Z" fill="black" fill-opacity="0.03"/>
<path d="M20 39.284C23.9449 39.284 27.1429 37.845 27.1429 36.0698C27.1429 34.2946 23.9449 32.8555 20 32.8555C16.0551 32.8555 12.8572 34.2946 12.8572 36.0698C12.8572 37.845 16.0551 39.284 20 39.284Z" fill="black" fill-opacity="0.05"/>
<path d="M20 38.5703C22.7614 38.5703 25 37.451 25 36.0703C25 34.6896 22.7614 33.5703 20 33.5703C17.2386 33.5703 15 34.6896 15 36.0703C15 37.451 17.2386 38.5703 20 38.5703Z" fill="black" fill-opacity="0.05"/>
<path d="M19.9999 37.8566C21.9723 37.8566 23.5713 37.0571 23.5713 36.0709C23.5713 35.0846 21.9723 34.2852 19.9999 34.2852C18.0274 34.2852 16.4285 35.0846 16.4285 36.0709C16.4285 37.0571 18.0274 37.8566 19.9999 37.8566Z" fill="black" fill-opacity="0.05"/>
<path d="M20 37.1429C21.1835 37.1429 22.1429 36.6632 22.1429 36.0714C22.1429 35.4797 21.1835 35 20 35C18.8166 35 17.8572 35.4797 17.8572 36.0714C17.8572 36.6632 18.8166 37.1429 20 37.1429Z" fill="black" fill-opacity="0.1"/>
<path d="M19.9999 36.4291C20.3944 36.4291 20.7142 36.2692 20.7142 36.072C20.7142 35.8747 20.3944 35.7148 19.9999 35.7148C19.6054 35.7148 19.2856 35.8747 19.2856 36.072C19.2856 36.2692 19.6054 36.4291 19.9999 36.4291Z" fill="black" fill-opacity="0.35"/>
<path d="M32.5 14.8529C32.5 8.03821 26.8959 2.5 20 2.5C13.1041 2.5 7.5 8.03821 7.5 14.8529C7.5 25 20 30.7143 20 36.0714C20 30.7143 32.5 25 32.5 14.8529Z" fill="{color}"/>
<path d="M20.0001 19.2863C22.367 19.2863 24.2858 17.3675 24.2858 15.0006C24.2858 12.6336 22.367 10.7148 20.0001 10.7148C17.6331 10.7148 15.7144 12.6336 15.7144 15.0006C15.7144 17.3675 17.6331 19.2863 20.0001 19.2863Z" fill="white"/>
</svg>
`;

export const REPORT_MARKER = {
    svgTpl: reportMarkerTpl,
    svgParams: {
        color: '#3356D7',
    },
    width: 40,
    height: 40,
    offset: {
        x: 0,
        y: -20,
    },
};
