<div
    *transloco="let t"
    class="sl-symbol-control bazis-control"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <label
            class="bazis-control__label"
            [class.bazis-h6]="hasTitleMajor"
            *ngIf="titleKey || title"
        >
            {{ title || t(titleKey, titleParams) }}
            <span
                color="danger"
                *ngIf="data.required"
            >
                *
            </span>
        </label>

        <div class="bh-flex bh-align-items-center">
            <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                <div
                    #tooltipLeft
                    class="bazis-control__field-group__start"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                </div>
                <bazis-tooltip
                    [reference]="tooltipLeft"
                    [eventType]="tooltipSettings?.eventType || 'hover'"
                    [placement]="tooltipSettings?.placement || 'bottom-start'"
                    >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                >
            </ng-container>
            <div
                *ngIf="fields.controls?.length > 0"
                #group
                class="bazis-control__field-group-wrapper"
            >
                <span
                    *ngFor="let field of fields.controls; let i = index"
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="fields.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <input
                        [type]="type"
                        class="bazis-control__field"
                        (input)="onInput(i)"
                        (keyup.backspace)="onBackspace(i)"
                        [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                        (focus)="onFocus(i)"
                        [formControl]="field"
                    />
                </span>
            </div>
            <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                <div
                    #tooltipRight
                    class="bazis-control__field-group__end"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                </div>
                <bazis-tooltip
                    [reference]="tooltipRight"
                    [eventType]="tooltipSettings?.eventType || 'hover'"
                    [placement]="tooltipSettings?.placement || 'bottom-end'"
                    >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                >
            </ng-container>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
    </ng-container>
</div>
