import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
} from '@angular/core';
import * as L from 'leaflet';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisMapService } from '@bazis/map/services/map.service';
import { MapTileSettings } from '@bazis/map/models/map.models';

@Component({
    selector: 'bazis-map-tiles-control',
    templateUrl: 'tiles.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisTilesComponent implements OnInit {
    @Input()
    tiles: MapTileSettings[];

    @Input()
    selectedTileId: string;

    @Output()
    setTileLayer: EventEmitter<MapTileSettings> = new EventEmitter();

    showList = false;

    constructor(private mapService: BazisMapService) {}

    ngOnInit() {}

    selectTile(tile: MapTileSettings) {
        this.showList = false;
        if (tile.id === this.selectedTileId) return;

        this.setTileLayer.emit(tile);
    }

    hideList() {
        this.showList = false;
    }
}
