import { Injectable } from '@angular/core';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BehaviorSubject, merge, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { combineLatestWith, filter, map, tap } from 'rxjs/operators';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { buildFilterStr } from '@bazis/utils';
import { TranslocoService } from '@jsverse/transloco';
import { API_DOC_LANGS_MAP } from '@bazis/configuration.service';
import { ListSettings } from '@bazis/list/models/list.types';

@Injectable({
    providedIn: 'root',
})
export class BazisFaqService {
    protected sectionsCache = new TemplateObservable({});

    protected subSectionsCache = new TemplateObservable({});

    protected subSectionsPreviewCache = new TemplateObservable({});

    protected questionsCache = new TemplateObservable({});

    lang$: Observable<string> = this.translocoService.langChanges$;

    sections$ = merge(this.lang$).pipe(
        withLatestFrom(this.sectionsCache.$),
        switchMap(([lang, cacheSections]) => {
            if (cacheSections[lang]?.length > 0) return of(cacheSections[lang]);
            return this.entityService
                .getEntityList$('faq.section', {
                    params: { sort: 'order', lang: API_DOC_LANGS_MAP[lang] },
                    limit: 1000,
                    saveSeparateItems: true,
                })
                .pipe(
                    map((result) => result.list),
                    tap((result) => {
                        this.sectionsCache.set({
                            ...this.sectionsCache._,
                            [lang]: result,
                        });
                    }),
                );
        }),
    );

    protected _searchText$ = new BehaviorSubject('');

    searchListSettings$: Observable<ListSettings> = this._searchText$.pipe(
        filter((text) => !!text),
        combineLatestWith(this.lang$),
        map(([text, lang]) => {
            return {
                entityType: 'faq.faq_item',
                skipSchema: true,
                params: {
                    translations__question__$search: text,
                    'translations__|answer__$search': text,
                    translations__language_code: API_DOC_LANGS_MAP[lang],
                },
                reflectInUrl: false,
                defaultSort: 'order',
                defaultStaticFilter: 'all',
                sortSettings: [],
                staticFilters: [],
                filters: {},
            };
        }),
    );

    constructor(
        private entityService: BazisEntityService,
        private translocoService: TranslocoService,
    ) {}

    searchByText(text) {
        this._searchText$.next(text);
    }

    getSubSectionBySection$(sectionId) {
        return of(null).pipe(
            withLatestFrom(this.subSectionsCache.$),
            combineLatestWith(this.lang$),
            switchMap(([[next, cacheSubsections], lang]) => {
                if (
                    cacheSubsections[lang] &&
                    cacheSubsections[lang][sectionId] &&
                    cacheSubsections[lang][sectionId].length > 0
                )
                    return of(cacheSubsections[lang][sectionId]);
                return this.entityService
                    .getEntityList$('faq.sub_section', {
                        params: {
                            filter: buildFilterStr({ section: sectionId }),
                            sort: 'order',
                            lang: API_DOC_LANGS_MAP[lang],
                        },
                        limit: 1000,
                        saveSeparateItems: true,
                    })
                    .pipe(
                        map((result) => result.list),
                        tap((result) => {
                            this.subSectionsCache.set({
                                ...this.subSectionsCache._,
                                [lang]: {
                                    ...this.subSectionsCache._[lang],
                                    [sectionId]: result,
                                },
                            });
                        }),
                    );
            }),
        );
    }

    getQuestionsBySubsection$(subsectionId) {
        return of(null).pipe(
            withLatestFrom(this.questionsCache.$),
            combineLatestWith(this.lang$),
            switchMap(([[next, cacheQuestions], lang]) => {
                if (
                    cacheQuestions[lang] &&
                    cacheQuestions[lang][subsectionId] &&
                    cacheQuestions[lang][subsectionId].length > 0
                )
                    return of(cacheQuestions[lang][subsectionId]);
                return this.entityService
                    .getEntityList$('faq.faq_item', {
                        params: {
                            filter: buildFilterStr({ subsection: subsectionId }),
                            sort: 'order',
                            lang: API_DOC_LANGS_MAP[lang],
                        },
                        limit: 1000,
                    })
                    .pipe(
                        map((result) => result.list),
                        tap((result) => {
                            this.questionsCache.set({
                                ...this.questionsCache._,
                                [lang]: {
                                    ...this.questionsCache._[lang],
                                    [subsectionId]: result,
                                },
                            });
                        }),
                    );
            }),
        );
    }

    getQuestionsPreviewBySubsection$(subsectionId, limit = 5) {
        return of(null).pipe(
            withLatestFrom(this.subSectionsPreviewCache.$),
            combineLatestWith(this.lang$),
            switchMap(([[next, cacheQuestions], lang]) => {
                if (
                    cacheQuestions[lang] &&
                    cacheQuestions[lang][subsectionId] &&
                    cacheQuestions[lang][subsectionId].length > 0
                )
                    return of(cacheQuestions[lang][subsectionId]);
                return this.entityService
                    .getEntityList$('faq.faq_item', {
                        params: {
                            filter: buildFilterStr({ subsection: subsectionId }),
                            sort: 'order',
                            lang: API_DOC_LANGS_MAP[lang],
                        },
                        limit,
                    })
                    .pipe(
                        map((result) => result.list),
                        tap((result) => {
                            this.subSectionsPreviewCache.set({
                                ...this.subSectionsPreviewCache._,
                                [lang]: {
                                    ...this.subSectionsPreviewCache._[lang],
                                    [subsectionId]: result,
                                },
                            });
                        }),
                    );
            }),
        );
    }
}
