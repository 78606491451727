import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazisInputOptionsComponent } from '@bazis/form/components/input-options.component';

// логика аналогичная, но визуал другой совсем, поэтому лучше отдельным компонентом
@Component({
    selector: 'bazis-input-options-button',
    templateUrl: './input-options-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputOptionsButtonComponent extends BazisInputOptionsComponent {
    @Input()
    allowToggle = false;

    hasLabelMajor = true;

    toggleValue(id) {
        if (!this.allowToggle && this.values._.indexOf(id) > -1) return;
        super.toggleValue(id);
    }
}
