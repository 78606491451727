<div
    *transloco="let t"
    class="bazis-modal-wrap"
>
    <div class="bazis-modal__header_shadow">
        <h3>{{ t('login.signUp.title') }}</h3>
    </div>
    <div class="bazis-modal__content">
        <form
            [formGroup]="form"
            autocomplete="off"
            class="sl-form"
        >
            <section class="sl-section">
                <h6>{{ t('login.signUp.organization') }}</h6>
                <bazis-grid class="bazis-grid_form">
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeMd="4"
                            sizeSm="6"
                            class="bh-next-empty"
                        >
                            <bazis-input-default
                                formControlName="tin"
                                titleKey="login.signUp.inn"
                                [required$]="required$"
                            ></bazis-input-default>
                        </bazis-col>
                        <bazis-col
                            class="bh-hide-sm-down"
                            sizeSm="6"
                            sizeMd="8"
                        ></bazis-col>
                    </bazis-row>
                </bazis-grid>
            </section>

            <section class="sl-section">
                <h6>{{ t('login.signUp.main') }}</h6>
                <bazis-grid class="bazis-grid_form">
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="4"
                        >
                            <bazis-input-default
                                formControlName="last_name"
                                titleKey="title.last_name"
                                [required$]="required$"
                            ></bazis-input-default>
                        </bazis-col>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="4"
                        >
                            <bazis-input-default
                                formControlName="first_name"
                                titleKey="title.first_name"
                                [required$]="required$"
                            ></bazis-input-default>
                        </bazis-col>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="4"
                        >
                            <bazis-input-default
                                formControlName="surname"
                                titleKey="title.middle_name"
                            ></bazis-input-default>
                        </bazis-col>
                    </bazis-row>
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeMd="4"
                            sizeSm="6"
                        >
                            <bazis-input-default
                                formControlName="email"
                                titleKey="title.email"
                                noteKey="login.signUp.useAsLogin"
                                [required$]="required$"
                            ></bazis-input-default>
                        </bazis-col>
                        <bazis-col
                            sizeXs="12"
                            sizeMd="4"
                            sizeSm="6"
                        >
                            <bazis-input-default
                                formControlName="contact_phone"
                                [maskSettings]="phoneMaskSettings"
                                [beforeWrite]="beforeWrite"
                                titleKey="title.phone"
                                leftIcon="/assets/icons/other/phone.svg"
                            ></bazis-input-default>
                        </bazis-col>
                        <bazis-col
                            class="bh-hide-md-down"
                            size="4"
                        ></bazis-col>
                    </bazis-row>
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeMd="4"
                            sizeSm="6"
                            class="bh-next-empty"
                        >
                            <bazis-input-password
                                formControlName="password"
                                titleKey="login.passwordField"
                                autocomplete="new-password"
                                [validationErrorMessages]="validationErrorMessages"
                                [required$]="required$"
                            ></bazis-input-password>
                        </bazis-col>
                        <bazis-col
                            class="bh-hide-sm-down"
                            sizeSm="6"
                            sizeMd="8"
                        ></bazis-col>
                    </bazis-row>
                </bazis-grid>
            </section>

            <section class="sl-section">
                <h6 class="bh-no-margin">{{ t('login.signUp.role') }}</h6>
                <p class="bazis-text_secondary bh-margin-bottom-5x">{{
                    t('login.signUp.roleDescription')
                }}</p>
                <bazis-input-options
                    formControlName="roles_id"
                    [options]="roles$ | async"
                ></bazis-input-options>
            </section>
        </form>
    </div>

    <div class="bazis-modal__footer">
        <bazis-toolbar>
            <div
                slot="start"
                class="bh-line-elements"
            >
                <bazis-button
                    color="action"
                    [disabled]="form.status !== 'VALID' || (signUp$ | async) === true"
                    (click)="signUp$.next(true)"
                >
                    {{ t('action.save') }}
                </bazis-button>

                <bazis-button
                    color="action"
                    fill="outline"
                    (click)="close()"
                >
                    {{ t('action.cancel') }}
                </bazis-button>
            </div>
        </bazis-toolbar>
    </div>

    <ng-container *ngIf="signingUp$ | async"></ng-container>
</div>
