import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Self,
    SimpleChanges,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { TooltipSettings, ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';

@UntilDestroy()
@Component({
    selector: 'bazis-input-number',
    templateUrl: './input-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputNumberComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() minNumber: number = null;

    @Input() maxNumber: number = null;

    @Input() increment: number = null;

    @Input() hasStepButton: boolean = false;

    @Input() precision = 12;

    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // ключ заголовка поля
    @Input() titleKey: string = null;

    // заголовок поля
    @Input() title: string = null;

    // пустой label, для сохранения отступа, когда поля в ряд.
    @Input() isEmptyLabel: boolean = false;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для placeholderKey
    @Input() placeholderKey: string = '';

    // параметры для placeholder
    @Input() placeholder: string = '';

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для title
    @Input() titleParams: any = null;

    // параметры для note
    @Input() noteParams: any = null;

    // иконка, которую надо вывести слева
    @Input() leftIcon: string = null;

    // иконка, которую надо вывести справа
    @Input() rightIcon: string = null;

    // является ли иконка справа кликабельной
    @Input() isRightIconClickable: boolean = null;

    // ед. измерения справа
    @Input() unitKey: string = null;

    // ед. измерения справа
    @Input() unit: string = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() required$: Observable<boolean> = null;

    @Input() forbiddenSymbols: string[] = ['e', 'E'];

    // событие по клику на правой иконке
    @Output() rightIconClick = new EventEmitter();

    //для всплытия соостояния
    @Output() touched = new EventEmitter();

    @Output() focused = new EventEmitter();

    @Output() blured = new EventEmitter();

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    // наличие стиралки для поля
    @Input() hasEraser: boolean = false;

    field = new FormControl<number | null>(null);

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    isFocused = false;

    canEraserShow = false;

    valueChanges$ = this.field.valueChanges.pipe(
        tap((value) => {
            let isFailValue = isNaN(value) || value === undefined;
            let formattedValue = this._formatValue(value);
            this.onChange(isFailValue || formattedValue !== value ? null : value);
        }),
    );

    constructor(@Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
        ngControl.valueAccessor = this;
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }

        this.canEraserShow = !this.field.disabled && this.hasEraser;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.cdr.markForCheck();
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.field.disable();
        } else {
            this.field.enable();
        }
    }

    public writeValue(value: any): void {
        const newValue = value === '' || value === undefined || value === null ? null : +value;
        this.field.setValue(newValue, { emitEvent: false });
    }

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
    }

    onRightIconClick(e) {
        this.markAsTouched();
        this.rightIconClick.emit(e);
    }

    addToValue(incrementValue) {
        const newValue = (+this.field.value || 0) + incrementValue;
        this.field.setValue(this._formatValue(newValue));
    }

    onBlur() {
        this.isFocused = false;
        if (this.field.value === null) return;
        const formattedValue = this._formatValue(this.field.value);
        if (this.field.value !== formattedValue) this.field.setValue(formattedValue);

        this.blured.emit();
    }

    onFocus() {
        this.markAsTouched();
        this.isFocused = true;
        this.focused.emit();
    }

    protected _formatValue(newValue) {
        if (newValue === null || newValue === '') return null;
        if (this.maxNumber !== null && this.maxNumber !== undefined && newValue > this.maxNumber) {
            newValue = this.maxNumber;
        }
        if (this.minNumber !== null && this.minNumber !== undefined && newValue < this.minNumber) {
            newValue = this.minNumber;
        }

        return +parseFloat(newValue).toFixed(this.precision);
    }

    onClear() {
        this.ngControl.control.setValue(null);
    }

    onKeyDown(e) {
        if (!this.forbiddenSymbols) return;
        if (this.forbiddenSymbols.includes(e.key)) e.preventDefault();
    }
}
